import React, {useEffect, useRef, useState} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import DataTable from "../../../components/DataTable";
import EditarNoticia from "../../../assets/images/editar_amarelo.svg";
import EliminarNoticia from "../../../assets/images/icone_eliminar.svg";

import "./styles.scss";
import {get, post} from "../../../VirbacProvider";
import moment from "moment";
import {useFormulario} from "../../../components/Formularios/Provider";

const TabelaNoticias = (props) => {
   const numero_resultados = props.numero_resultados || 6;
   const [pagina, setPagina] = useState(0);
   const [pesquisa, setPesquisa] = useState("");
   const [noticias, setNoticias] = useState(null)
   const formulario = useFormulario();

   const obterNoticias = async () => {
      let noticias = await get("/noticia/listar", {
         pagina,
         numero_resultados,
         pesquisa
      });
      setNoticias(noticias);
   }
   useEffect(() => {
      obterNoticias();
   }, [pagina, pesquisa]);

   const callbackReloadTabelaNoticias = useRef(null);
   const reloadTabelaNoticias = () => {
      callbackReloadTabelaNoticias.current && callbackReloadTabelaNoticias.current();
   }

   useEffect(() => {
      // Registar callback para recarregar listagem
      if (callbackReloadTabelaNoticias)
         callbackReloadTabelaNoticias.current = obterNoticias;
   }, [])

   if (!noticias)
      return null

   let columns = [
      {
         dataField: 'DataCriacao',
         text: 'Data',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (data) => {
            return (
               <p className={"m-0"}>{moment(data).format("DD/MM/YYYY")}</p>
            );
         }
      }, {
         dataField: 'Titulo',
         text: 'Título',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         dataField: 'Destino',
         text: 'Destinatários',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (destino) => {
            return (
               <p className={"m-0 text-capitalize"}>{destino}</p>
            );
         }
      }, {
         dataField: 'action',
         text: 'Ações',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, noticia) => {
            return (
               <div className={"d-flex"}>
                  <img className={"mr-2 cursor-pointer"} src={EditarNoticia} alt={"botao editar noticia"} onClick={async () => {
                     let detalhes = await get("noticia/" + noticia.id);

                     formulario.abrirFormulario("nova_noticia", {
                        titulo: "Editar notícia",
                        valoresIniciais: {
                           Titulo: detalhes.Titulo,
                           Corpo: detalhes.Corpo,
                           Destino: detalhes.Destino,
                           ImagemAtual: detalhes.Imagem
                        },
                        extra: {
                           acao: "editar",
                           IdNoticia: detalhes.id
                        },
                        formularioSubmetido: () => {
                           reloadTabelaNoticias();
                        }
                     })
                  }}
                  />
                  <img className={"cursor-pointer"} src={EliminarNoticia} alt={"botao eliminar noticia"} onClick={() => {
                     confirmAlert({
                        title: `Apagar Notícia?`,
                        message: `Confirma que pretende eliminar a notícia '${noticia.Titulo}'? Esta ação é irreversível.`,
                        buttons: [
                           {
                              label: 'Sim',
                              onClick: async () => {
                                 await post("/noticia/apagar", {
                                    id: noticia.id
                                 });
                                 reloadTabelaNoticias();
                              }
                           },
                           {label: 'Cancelar'}
                        ]
                     });
                  }}
                  />
               </div>
            );
         }
      }
   ];

   return <div className={"TabelaNoticias"}>
      <DataTable
         data={noticias.resultados}
         columns={columns}
         title={"Notícias"}
         showSearchBar={true}
         searchPlaceholder={"Pesquisar"}
         exportUrl={props.exportUrl}
         showNewButton={true}
         newButtonName={"Nova Notícia"}
         newOnClick={() => formulario.abrirFormulario("nova_noticia", {
            formularioSubmetido: () => {
               reloadTabelaNoticias();
            },
            extra: {
               acao: "adicionar"
            }
         })}
         onPageChange={page => setPagina(page - 1)}
         onSearchChange={setPesquisa}
         paginationOptions={{
            page: pagina + 1,
            sizePerPage: numero_resultados,
            totalSize: noticias.paginacao.total_resultados
         }}
      />
   </div>
}

export default TabelaNoticias;