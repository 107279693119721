import React from 'react';

export default ({values, register, errors}) => {

   return <div className={"NovoEscalao"}>
      <div className={"form-group"}>
         <label data-error={errors.Designacao?.message}>
            Designação
            <input name="Designacao" placeholder="Designação" type="text" className="form-control form-control-sm" ref={register({
               required: "Por favor insira a designação do escalão"
            })}/>
         </label>
      </div>
      <div className={"form-group"}>
         <label data-error={errors.Pontos?.message}>
            Pontos
            <input name="Pontos" placeholder="Pontos" type="number" min="1" className="form-control form-control-sm" ref={register({
               required: "Por favor insira os pontos do escalão"
            })}/>
         </label>
      </div>
   </div>
}