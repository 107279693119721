import React, {useEffect, useState} from "react";
import axios from "axios";

const axios_instance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   withCredentials: true
});

const VirbacContext = React.createContext({});

const get = async (url, data) => {
   return (await axios_instance.get(url, {params: data})).data;
};

const post = async (url, data) => {
   return (await axios_instance.post(url, data)).data;
};

const VirbacProvider = (props) => {
   const [utilizador, setUtilizador] = useState(null);

   useEffect(() => {
      (async () => {
         try {
            let utilizador = await get("/utilizador/informacoes");
            setUtilizador(utilizador.utilizador);
         } catch (e) {
            setUtilizador(false);
         }
      })();
   }, []);

   const login = async (dados) => {
      let utilizador = await post("/utilizador/login", dados);
      setUtilizador(utilizador.utilizador);
   };

   const logout = async () => {
      await post("/utilizador/logout");
      setUtilizador(false);
   };

   return <VirbacContext.Provider value={{get, post, login, logout, utilizador}}>
      {props.children}
   </VirbacContext.Provider>
};

const useVirbac = () => React.useContext(VirbacContext);

export default VirbacProvider;
export {useVirbac, get, post};