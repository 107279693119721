import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   await post("/movimento/editar", {
      id: opcoes.extra.IdMovimento,
      Produto: opcoes.extra.IdProduto,
      Quantidade: valores.produtos[0].quantidade
   })

   cogoToast.success('Movimento editado com sucesso.', toastoptions)
}

const EditarMovimento = {
   titulo: "Editar Movimento",
   submeter,
   etapas
}

export default EditarMovimento;