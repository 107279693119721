import React, {useEffect, useState} from "react";
import {useVirbac} from "../../../../VirbacProvider";
import EnviarImagem from "../../../../assets/images/enviar_imagem.svg";

import './styles.scss';

export default ({values, register, errors}) => {
   const [escaloes, setEscaloes] = useState(null);
   const [previewImagem, setPreviewImagem] = useState(null);
   const virbac = useVirbac();

   useEffect(() => {
      (async () => {
         let escaloes = await virbac.get("/produto/escalao/listar", {numero_resultados: 0});
         setEscaloes(escaloes);
      })()
   }, []);

   useEffect(() => {
      if (values.Imagem && values.Imagem.length > 0) {
         let file = values.Imagem[0];

         // Make sure `file.name` matches our extensions criteria
         if (file.type.startsWith("image/")) {
            let reader = new FileReader();

            reader.addEventListener("load", function () {
               setPreviewImagem(this.result);
            }, false);

            reader.readAsDataURL(file);
         }

      }
   }, [values.Imagem]);

   if (!escaloes)
      return null;

   return <div className={"EditarProduto"}>
      <input type={"hidden"} name={"id"} ref={register}/>
      <div className="row">
         <div className="col-md-3">
            <div className="form-group">
               <label data-error={errors.Codigo?.message}>
                  Referência
                  <input ref={register({
                     required: "Por favor indique a referência do produto"
                  })} name="Codigo" placeholder="Referência" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-9">
            <div className="form-group">
               <label data-error={errors.Nome?.message}>
                  Nome
                  <input ref={register({
                     required: "Por favor indique o nome do produto"
                  })} name="Nome" placeholder="Nome" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="form-group">
         <label data-error={errors.Descricao?.message}>
            Descrição
            <textarea ref={register({
               required: "Por favor introduza uma descrição do produto"
            })} name="Descricao" placeholder="Descrição" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="row">
         <div className="col-md-7">
            <div className="form-group">
               <label data-error={errors.Pontos?.message}>
                  Pontos que acumula
                  <input ref={register({
                     required: "Por favor indique os pontos do produto"
                  })} name="Pontos" placeholder="Pontos" type="text" className="col-md-7 form-control form-control-sm"/>
               </label>
            </div>
            <div className="form-group">
               <label data-error={errors.Escalao?.message}>
                  Pontos necessários para desconto (Escalão)
                  <select
                     name={"Escalao"}
                     className={"col-md-7 form-control" + (values.Escalao !== null ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique o escalão do produto"
                     })}>
                     <option value={""} disabled selected>Escalão</option>
                     {escaloes.resultados.map(escalao => {
                        return <option value={escalao.id}>{escalao.Designacao}</option>
                     })}
                  </select>
               </label>
            </div>
         </div>
         <div className="col-md-5 stretch-card">
            <label data-error={errors.Imagem?.message} className="d-flex flex-column align-items-center justify-content-center mb-3 mt-2 py-2 carregar-imagem">
               <img src={EnviarImagem} alt={"botao carregar comprovativo"}/>
               <p className="mb-0 mt-1 text-comprovativo">Enviar Imagem</p>
               <input type={"hidden"} name={"ImagemAtual"} ref={register}/>
               <input
                  className={"d-none"}
                  type={"file"}
                  name={"Imagem"}
                  ref={register({
                     required: values.ImagemAtual ? false : "Por favor carregue a imagem do produto"
                  })}
                  accept={"image/*"}/>
               {(previewImagem || values.ImagemAtual) && <div
                  className={"previsualizacao_imagem"}
                  style={{
                     backgroundImage: previewImagem ?
                        `url(${previewImagem})` :
                        `url(${process.env.REACT_APP_API_URL}/public/produtos/${values.ImagemAtual}.jpg)`
                  }}/>}
            </label>
         </div>
      </div>
      <div className={"row"}>
         <div className={"col-md"}>
            <div className="form-check mt-1 mb-0">
               <label className="form-check-label label-checkbox" data-error={errors.Ativo?.message}>
                  <input name="Ativo" type="checkbox" className="form-check-input"
                         ref={register}/>
                  <i className="input-helper"></i>
                  Produto Ativo
               </label>
            </div>
         </div>
      </div>
   </div>;
}