import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {get, useVirbac} from "../../VirbacProvider";
import {useFormulario} from "../../components/Formularios/Provider";
import TabelaHistorico from "../../components/TabelaHistorico";
import Widget from "../../components/Widget";
import parse from "html-react-parser";

import EditarProduto from "../../assets/images/editar.svg";

import './styles.scss';

const Produto = () => {
   const [produto, setProduto] = useState(null);
   const virbac = useVirbac();
   const history = useHistory();
   const formulario = useFormulario();
   let {idProduto} = useParams();

   const obterProduto = async () => {
      let produto = await get(`/produto/${idProduto}`);
      setProduto(produto);
   }

   useEffect(() => {
      obterProduto();
   }, []);

   if (!produto)
      return null;

   return <div className={"Produto"}>
      <a href={"#"} className={"primary-color font-weight-bold"} onClick={(e) => {
         e.preventDefault();
         history.goBack()
      }}>&lt; Voltar</a>

      <div className={"card mt-4 px-5 pt-5 pb-3 product-info"}>
         <div className={"row"}>
            <div className={"col-md-4"}>
               <div style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/public/produtos/${produto.Imagem}.jpg)`}} className={"product-image"}/>
               <div className={"mt-2"}>
                  <p className={"mb-1 font-weight-bold"}>Pontos acumulados:</p>
                  <div className={"badge badge-points primary-color mr-2 badge-inverse-dark"}>{produto.Pontos}</div>
               </div>
               {!!produto.Escalao && <div className={"mt-2"}>
                  <p className={"mb-1 font-weight-bold"}>Pontos necessários para desconto:</p>
                  <div className="badge badge-points primary-color badge-inverse-dark mb-4">
                     <i className="badge-icon typcn typcn-gift primary-color mr-1"/>
                     {produto.Escalao.Pontos}
                  </div>
               </div>}
            </div>

            <div className={"col-md-8"}>
               <div className={"d-flex justify-content-between pb-2"}>
                  <h3 className={"m-0 font-weight-bold"}>{produto.Nome}</h3>
                  {virbac.utilizador.Tipo === "admin" &&
                     <img src={EditarProduto} alt={"botao editar produto"} className={"cursor-pointer"} onClick={() => {
                        formulario.abrirFormulario("editar_produto", {
                           valoresIniciais: {
                              Codigo: produto.Codigo,
                              Nome: produto.Nome,
                              Descricao: produto.Descricao,
                              Pontos: produto.Pontos,
                              ImagemAtual: produto.Imagem,
                              Escalao: produto.Escalao && produto.Escalao.id,
                              Ativo: produto.Ativo
                           },
                           extra: {
                              acao: "editar",
                              IdProduto: produto.id
                           },
                           formularioSubmetido: async () => {
                              obterProduto();
                           }
                        })
                     }}/>}
               </div>

               <h5 className={"mb-4 product-code"}>{produto.Codigo}</h5>

               <div className={"primary-color descricao"}>
                  {produto.Descricao && parse(produto.Descricao.replace(/\n/g, "<br>") || "")}
               </div>

            </div>
         </div>
      </div>

      {virbac.utilizador.Tipo !== "cliente" &&
         <div className={"row mt-4 mb-5"}>
            <div className={"col-md-8 stretch-card"}>
               <TabelaHistorico
                  titulo={"Histórico de vendas"}
                  colunas={["data", "cliente", "tipo_movimento"]}
                  numeroResultados={5}
                  filtro={{idProduto}}
               />
            </div>

            <Widget
               estatisticas={[{
                  label: "Vendas este mês:",
                  valor: `${produto.Estatisticas.VendasMes} unidades`,
                  variacao: produto.Estatisticas.VendasMes - produto.Estatisticas.VendasMesAnterior,
               }, {
                  label: "Vendas este ano:",
                  valor: `${produto.Estatisticas.VendasAno} unidades`
               }]}
               grafico={[25, 20, 40, 30, 15, 10, 50]}
               cor={"#b66dff"}/>
         </div>
      }
   </div>
}

export default Produto;