import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require("./0").default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.Utilizador = opcoes.extra.idCliente;

   let cliente = await post("/cliente/editar", valores);

   if (opcoes.extra.acao === "editar_perfil")
      cogoToast.success('Perfil editado com sucesso!', toastoptions);

   if (opcoes.extra.acao === "editar_cliente")
      cogoToast.success('Cliente editado com sucesso!', toastoptions);

   return cliente;
};

const EditarCliente = {
   titulo: "Dados do Cliente",
   submeter,
   etapas
};

export default EditarCliente;