import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require("./0").default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
};

const submeter = async (valores, opcoes) => {
   let clinica;
   if (opcoes.extra?.acao === "editar") {
      valores.id = opcoes.extra.IdClinica;
      clinica = await post("clinica/editar", valores);
      cogoToast.success('Clínica editada com sucesso', toastoptions);
   } else {
      clinica = await post("clinica/adicionar", valores);
      cogoToast.success('Clínica criada com sucesso!', toastoptions);
   }
   return clinica;
};

const CriarClinica = {
   titulo: "Nova Clínica",
   submeter,
   etapas
};

export default CriarClinica;