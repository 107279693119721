import React from 'react';
import TabelaClinicas from "../../components/TabelaClinicas";

const Gerente = () => {

   return <div>
      <TabelaClinicas
          colunas={["contribuinte", "nome_comercial", "localidade", "telefone", "email", "accoes"]}
      />
   </div>
};

export default Gerente;

