import React from 'react';
import TabelaProdutos from "../../components/TabelaProdutos";
import TabelaEscaloes from "./TabelaEscaloes";

const Produtos = () => {

   return <div className={"Produtos"}>
      <TabelaProdutos
          colunas={["imagem", "codigo", "nome", "pontos", "escalao", "accoes"]}
          exportUrl={`${process.env.REACT_APP_API_URL}/produto/listar?exportar_excel`}
          showNewButton={true}
          newButtonName={"Novo produto"}
      />

      <div className={"row"}>
         <div className={"col-md-5"}>
            <TabelaEscaloes/>
         </div>
      </div>
   </div>
};

export default Produtos;