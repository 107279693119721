import CriarClinica from "./criar_clinica";
import NovoCliente from "./novo_cliente";
import EditarCliente from "./editar_cliente";
import ClienteAdicionarAnimal from "./cliente_adicionar_animal";
import NovaNoticia from "./nova_noticia";
import NovaVenda from "./nova_venda";
import EditarProduto from "./editar_produto";
import EliminarConta from "./eliminar_conta";
import NovoEscalao from "./novo_escalao";
import NovoDesconto from "./novo_desconto";
import EditarMovimento from "./editar_movimento";
import NovoDelegado from "./novo_delegado";
import ImportarPVP from "./importar_pvp";
import NovaCampanhaSMS from "./nova_campanha_sms";
import RecuperarPassword from "./recuperar_password";
import EditarClinica from "./editar_clinica";

const obterConfiguracao = (nome_formulario) => {
   let configuracao = ({
      criar_clinica: CriarClinica,
      novo_cliente: NovoCliente,
      editar_cliente: EditarCliente,
      eliminar_conta: EliminarConta,
      cliente_adicionar_animal: ClienteAdicionarAnimal,
      nova_noticia: NovaNoticia,
      nova_venda: NovaVenda,
      editar_movimento: EditarMovimento,
      novo_desconto: NovoDesconto,
      editar_produto: EditarProduto,
      editar_clinica: EditarClinica,
      novo_escalao: NovoEscalao,
      novo_delegado: NovoDelegado,
      importar_pvp: ImportarPVP,
      nova_campanha_sms: NovaCampanhaSMS,
      recuperar_password: RecuperarPassword,
   })[nome_formulario];
   if (!configuracao)
      throw new Error("Não existe nenhuma configuração para o formulário '" + nome_formulario + "'");
   return configuracao;
};

export default obterConfiguracao;