import React from 'react';

import Cao from "../../../assets/images/cao.svg";
import Gato from "../../../assets/images/gato.svg";
import EditarAnimal from "../../../assets/images/editar_white.svg";

import './styles.scss';

const CardAnimal = (props) => {

   return <div className={"col-md-3 CardAnimal mb-4 stretch-card"}>
      <div className={"card card-padding card-animal mb-2"}>
         {props.tipo === "cao" &&
         <div>
            <div className={"text-center"}>
               <img src={Cao} className={"mb-3 imagem-animal"} alt={"tipo animal cao"}/>
            </div>

            <p className={"m-0 font-weight-bold tipo-animal text-uppercase"}>Cão</p>
         </div>
         }

         {props.tipo === "gato" &&
         <div>
            <div className={"text-center"}>
               <img src={Gato} className={"mb-3 imagem-animal"} alt={"tipo animal gato"}/>
            </div>

            <p className={"m-0 font-weight-bold tipo-animal text-uppercase"}>Gato</p>
         </div>
         }
         <p className={"m-0 font-weight-bold nome-animal"}>{props.nome}</p>
         <div className={"d-flex justify-content-between mt-2"}>
            <div>
               <p className={"m-0"}>{props.raca}</p>
               {props.sexo === "m" &&
               <p className={"m-0"}>Sexo masculino</p>
               }

               {props.sexo === "f" &&
               <p className={"m-0"}>Sexo feminino</p>
               }
            </div>

            <img src={EditarAnimal} alt={"botao editar animal"} className={"cursor-pointer"} onClick={props.onClick}/>
         </div>
      </div>
   </div>
};

export default CardAnimal;