import React from 'react';
import TabelaClinicas from "../../components/TabelaClinicas";

const Clinicas = () => {

   return <div className={"Clinicas"}>
      <TabelaClinicas
          colunas={["nome_comercial", "localidade", "telefone", "email", "accoes"]}
          numero_resultados={15}
          exportUrl={`${process.env.REACT_APP_API_URL}/clinica/listar?exportar_excel`}
      />
   </div>
};

export default Clinicas;