import React, {useEffect, useState} from 'react';

import EnviarImagem from "../../../../assets/images/enviar_imagem.svg";

import './styles.scss';

export default ({values, register, errors, extra}) => {
   const [previewImagem, setPreviewImagem] = useState(null);

   useEffect(() => {
      if (values.Imagem && values.Imagem.length > 0) {
         let file = values.Imagem[0];

         // Make sure `file.name` matches our extensions criteria
         if (file.type.startsWith("image/")) {
            let reader = new FileReader();

            reader.addEventListener("load", function () {
               setPreviewImagem(this.result);
            }, false);

            reader.readAsDataURL(file);
         }

      }
   }, [values.Imagem]);

   return <div className={"NovaNoticia"}>
      <div className="form-group">
         <label data-error={errors.Titulo?.message}>
            Título
            <input name="Titulo"
                   placeholder="Título"
                   type="text"
                   className="form-control form-control-sm"
                   ref={register({
                      required: "Por favor insira o título da notícia"
                   })}/>
         </label>
      </div>
      <div className="form-group">
         <label data-error={errors.Corpo?.message}>
            Texto
            <textarea
               name="Corpo"
               placeholder="Texto da notícia"
               className="corpo-noticia form-control"
               ref={register({
                  required: "Por favor insira o corpo da notícia"
               })}
            />
         </label>
      </div>
      <div className="row">
         <div className="col-md-6 stretch-card">
            <label data-error={errors.Imagem?.message} className="d-flex flex-column align-items-center justify-content-center py-2 carregar-imagem">
               <img src={EnviarImagem} alt={"botao carregar comprovativo"}/>
               <p className="mb-0 mt-1 text-comprovativo">Enviar Imagem</p>
               <input type={"hidden"} name={"ImagemAtual"} ref={register}/>
               <input
                  className={"d-none"}
                  type={"file"}
                  name={"Imagem"}
                  ref={register({
                     required: values.ImagemAtual ? false : "Por favor carregue a imagem da notícia"
                  })}
                  accept={"image/*"}/>
               {(previewImagem || values.ImagemAtual) && <div
                  className={"previsualizacao_imagem"}
                  style={{
                     backgroundImage: previewImagem ?
                        `url(${previewImagem})` :
                        `url(${process.env.REACT_APP_API_URL}/public/noticias/${values.ImagemAtual}.jpg)`
                  }}/>}
            </label>
         </div>
         <div className="col-md-4 col my-3">
            <div className="form-group">
               <label data-error={errors.Destino?.message}>
                  Destinatários
                  <select
                     name={"Destino"}
                     className={"form-control" + (values.Destino ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique os destinatários da notícia"
                     })}>
                     <option value={""} disabled selected>Destinatarios</option>
                     <option value={"todos"}>Todos</option>
                     <option value={"clientes"}>Clientes</option>
                     <option value={"clinicas"}>Clínicas</option>
                  </select>
               </label>
            </div>
            {extra.acao === "adicionar" &&
            <div className="form-check mt-4">
               <label className="form-check-label label-checkbox d-flex align-items-center">
                  <input name="EnviarEmail" type="checkbox" className="form-check-input" ref={register}/>
                  <i className="input-helper"></i>
                  Enviar notícia por email
               </label>
            </div>
            }
         </div>
      </div>
   </div>

}

