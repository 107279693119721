import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require("./0").default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.id = opcoes.extra.idClinica;

   let clinica = await post("/clinica/editar", valores);

   cogoToast.success('Informações alteradas com sucesso.', toastoptions);

   return clinica;
};

const EditarClinica = {
   titulo: "Alterar informações",
   submeter,
   etapas
};

export default EditarClinica;