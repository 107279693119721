import React from 'react';

import AdicionarAnimal from "../../../assets/images/add_icon.svg";

import './styles.scss';

const CardNovoAnimal = (props) => {

   return <div className={"CardNovoAnimal col-md-3 stretch-card mb-4 cursor-pointer"} onClick={props.onClick}>
      <div className={"d-flex flex-column justify-content-center align-items-center card card-padding card-novo-animal mb-2"}>
         <img src={AdicionarAnimal} className={"mb-2"} alt={"botão adicionar animal"}/>
         <strong className={"novo-animal-text"}>Novo animal</strong>
      </div>
   </div>
};

export default CardNovoAnimal;