import React, {useEffect, useRef, useState} from 'react';
import DataTable from "../../../components/DataTable";
import {get, post} from "../../../VirbacProvider";
import {useFormulario} from "../../../components/Formularios/Provider";

import EditarDelegado from "../../../assets/images/editar_amarelo.svg";
import Eliminar from "../../../assets/images/icone_eliminar.svg";

import {confirmAlert} from "react-confirm-alert";

const TabelaDelegados = (props) => {
   const numero_resultados = props.numeroResultados || 6;
   const [delegados, setDelegados] = useState(null);
   const [pagina, setPagina] = useState(0);
   const [pesquisa, setPesquisa] = useState("");
   const formulario = useFormulario();

   let columns = [
      {
         key: "nome",
         dataField: 'Nome',
         text: 'Nome',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         key: "email",
         dataField: "Email",
         text: 'Email',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         key: "telemovel",
         dataField: "Telefone",
         text: 'Telemóvel',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         key: "accoes",
         dataField: 'action',
         text: 'Ações',
         sort: false,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, delegado) => {
            return (<>
               <img className={"cursor-pointer mr-2"} src={EditarDelegado} alt={"botao editar delegado"} onClick={() => formulario.abrirFormulario("novo_delegado", {
                  valoresIniciais: {
                     Nome: delegado.Nome,
                     Email: delegado.Email,
                     Telefone: delegado.Telefone
                  },
                  extra: {
                     acao: "editar",
                     IdDelegado: delegado.id
                  },
                  formularioSubmetido: () => {
                     reloadTabelaDelegados();
                  }
               })}/>
               <img className={"cursor-pointer"} src={Eliminar} alt={"botao eliminar delegado"} onClick={() => {
                  confirmAlert({
                     title: `Apagar Delegado?`,
                     message: `Confirma que pretende eliminar o delegado '${delegado.Nome}'? Esta ação é irreversível.`,
                     buttons: [
                        {
                           label: 'Sim',
                           onClick: async () => {
                              await post("/delegado/apagar", {
                                 id: delegado.id
                              });
                              reloadTabelaDelegados();
                           }
                        },
                        {label: 'Cancelar'}
                     ]
                  });
               }}
               />
            </>);
         }
      }
   ];

   const obterDelegados = async () => {
      let delegados = await get("/delegado/listar", {
         pagina,
         numero_resultados,
         pesquisa
      });
      setDelegados(delegados);
   };

   useEffect(() => {
      obterDelegados();
   }, [pagina, pesquisa]);

   const callbackReloadTabelaDelegados = useRef(null);
   const reloadTabelaDelegados = () => {
      callbackReloadTabelaDelegados.current && callbackReloadTabelaDelegados.current();
   }

   useEffect(() => {
      // Registar callback para recarregar listagem
      if (callbackReloadTabelaDelegados)
         callbackReloadTabelaDelegados.current = obterDelegados;
   }, [])

   if (!delegados)
      return null;

   return <DataTable
      className={"TabelaDelegados"}
      data={delegados.resultados}
      columns={columns}
      paginationOptions={{
         page: pagina + 1,
         sizePerPage: numero_resultados,
         totalSize: delegados.paginacao.total_resultados
      }}
      title={props.titulo}
      showSearchBar={true}
      searchPlaceholder={"Pesquisar delegado"}
      showNewButton={true}
      newButtonName={"Novo Delegado"}
      newOnClick={() => {
         formulario.abrirFormulario("novo_delegado", {
            formularioSubmetido: () => {
               reloadTabelaDelegados();
            },
            extra: {
               acao: "adicionar"
            }
         })
      }}
      exportUrl={`${process.env.REACT_APP_API_URL}/delegado/listar?exportar_excel`}
      onPageChange={page => setPagina(page - 1)}
      onSearchChange={setPesquisa}
   />
}

export default TabelaDelegados;