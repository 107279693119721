import React, {useMemo, useState} from 'react';

import ProcurarCliente from "../../../ProcurarCliente";
import ProcurarProduto from "../../../ProcurarProduto";

import InfoComprovativoIcon from '../../../../assets/images/comprovativo_info_icon.svg';
import CarregarComprovativo from '../../../../assets/images/carregar_comprovativo.svg';

import './styles.scss';

export default ({values, register, errors}) => {
   const [numeroProdutos, setNumeroProdutos] = useState(3);

   let pedirComprovativo = useMemo(() => {
      let produtos = {};
      if (values.produtos)
         values.produtos.forEach(produto => {
            if (!produto.quantidade || parseInt(produto.quantidade) === 0 || !produto.codigo)
               return;
            produto.codigo in produtos ? produtos[produto.codigo] += parseInt(produto.quantidade) : produtos[produto.codigo] = parseInt(produto.quantidade);
         });
      return Object.keys(produtos).length >= 4 || Object.values(produtos).find(quantidade => quantidade >= 4);
   }, [values.produtos]);

   return <div className={"NovaVenda"}>
      <ProcurarCliente values={values} register={register} errors={errors}/>
      <div>
         <div>
            <div className="form-group mb-1">
               <label>
                  Produtos
               </label>
            </div>
         </div>
         {[...Array(numeroProdutos).keys()].map(idxProduto => {
            return <div className={"Produto"}>
               <div className="row">
                  <div className="col-3">
                     <div className="form-group mb-0">
                        <label data-error={errors.produtos && errors.produtos[idxProduto]?.quantidade?.message}>
                           <select
                              ref={register}
                              name={"produtos[" + idxProduto + "].quantidade"}
                              className={"form-control" + (values.produtos && values.produtos[idxProduto]?.quantidade ? " ok" : "")}>
                              <option value={""} disabled selected>Quantidade</option>
                              {[...Array(21).keys()].map(i => {
                                 if (idxProduto === 0)
                                    return <option value={i + 1}>{i + 1}</option>;
                                 return <option value={i}>{i}</option>;
                              })}
                           </select>
                        </label>
                     </div>
                  </div>
                  <div className="col-9">
                     <ProcurarProduto {...{
                        errors,
                        values,
                        register,
                        idxProduto
                     }} />
                  </div>
               </div>
            </div>;
         })}
         <div className={"mt-3"}>
            <button className={"btn btn-xs btn-primary"} onClick={() => setNumeroProdutos(numeroProdutos + 1)}>+ Adicionar mais</button>
         </div>
         <div className={pedirComprovativo ? "" : "d-none"}>
            <hr/>
            <div className="row m-0">
               <div className="col-7 p-0 d-flex align-items-center">
                  <img src={InfoComprovativoIcon} alt={""} className={"mr-3"}/>
                  <p className={"m-0 text-comprovativo"}>Para prosseguir com esta venda, deverá anexar o respetivo comprovativo:</p>
               </div>
               <div className="col-5 p-0 form-group m-0 d-flex flex-column justify-content-center">
                  <label data-error={errors.Comprovativo?.message}>
                     <div className={"d-flex align-items-center"}>
                        <img src={CarregarComprovativo} alt={"botao carregar comprovativo"} className={"mr-2"}/>
                        <p className="m-0 text-comprovativo text-uppercase underline">
                           Carregar comprovativo
                           {values.Comprovativo?.length > 0 ? <small>{values.Comprovativo[0].name}</small> : ""}
                        </p>
                     </div>
                     <input
                        className={"d-none"}
                        type={"file"}
                        name={"Comprovativo"}
                        ref={register({
                           required: pedirComprovativo ? "Por favor carregue o comprovativo" : false
                        })}
                        accept={"image/*,application/pdf"}/>
                  </label>
               </div>
            </div>
         </div>
      </div>
   </div>
}