import React, {useEffect, useState} from 'react';
import {useFormulario} from "../../components/Formularios/Provider";
import {get, useVirbac} from "../../VirbacProvider";

import Widget from "../../components/Widget";
import Noticias from "../../components/Noticias";
import TabelaClientes from "../../components/TabelaClientes";
import TabelaProdutos from "../../components/TabelaProdutos";
import {useHistory} from "react-router-dom";

import RegistarVenda from "../../assets/images/registar_venda.svg";
import AdicionarCliente from "../../assets/images/adicionar_cliente.svg";

import './styles.scss';
import TabelaHistorico from "../../components/TabelaHistorico";
import RegistarDesconto from "../../assets/images/registar_desconto.svg";

const Dashboard = () => {
   const formulario = useFormulario();
   const [clinica, setClinica] = useState(null)
   const [noticias, setNoticias] = useState(null)
   const virbac = useVirbac();
   const history = useHistory();

   const obterClinica = async () => {
      let clinica = await get(`/clinica/${virbac.utilizador.id}`);
      setClinica(clinica);
   };

   useEffect(() => {
      obterClinica();
      (async () => {
         let noticias = await get("/noticia/listar");
         setNoticias(noticias.resultados);
      })();
   }, []);

   if (!clinica)
      return null;

   let estatisticas = clinica.Estatisticas;

   return <div className={"Dashboard"}>
      <Noticias data={noticias}/>

      <div className={"row"}>
         <div className={"col-md-5 grid-margin stretch-card"}>
            <div className={"card"}>
               <div className={"clinic-info-card"}>
                  <i className="typcn typcn-cog editar-dados-icon" onClick={() => {
                     formulario.abrirFormulario("editar_clinica", {
                        valoresIniciais: {
                           NomeComercial: clinica.NomeComercial
                        },
                        extra: {
                           idClinica: clinica.id
                        },
                        formularioSubmetido: async () => {
                           await obterClinica();
                        }
                     })
                  }}/>
                  <strong>{clinica.NomeComercial}</strong>
                  <p> {clinica.Morada}</p>
                  <p><strong>NIF</strong> {clinica.Contribuinte}</p>
                  <p><strong>Telefone</strong> {clinica.Telefone}</p>
                  <p><strong>Email</strong> {clinica.Email}</p>
               </div>
            </div>
         </div>

         <div className={"col-md-3 grid-margin stretch-card registar-venda-container"} onClick={() => {
            formulario.abrirFormulario("nova_venda", {
               valoresIniciais: {
                  produtos: [{quantidade: 1}]
               },
               formularioSubmetido: (resultado) => history.push(`clientes/${resultado.idCliente}`)
            })
         }}>
            <div className={"py-3 card operation-card rounded"}>
               <img src={RegistarVenda} className={"mb-2"} alt={"botão registar venda"}/>
               <p>
                  Registar<br/>
                  Venda
               </p>
            </div>
         </div>

         <div className={"col-md-2 col-6 grid-margin stretch-card registar-venda-container"} onClick={() => {
            formulario.abrirFormulario("novo_desconto", {
               formularioSubmetido: (resultado) => history.push(`clientes/${resultado.idCliente}`)
            });
         }}>
            <div className={"py-3 card operation-card rounded"}>
               <img src={RegistarDesconto} className={"mb-2"} alt={"botão registar desconto"}/>
               <p>
                  Registar<br/>
                  Desconto
               </p>
            </div>
         </div>

         <div className={"col-md-2 col-6 grid-margin stretch-card adicionar-cliente-container rounded"} onClick={() => {
            formulario.abrirFormulario("novo_cliente", {
               formularioSubmetido: (resultado) => history.push(`clientes/${resultado.id}`)
            });
         }}>
            <div className={"py-3 card operation-card rounded"}>
               <img src={AdicionarCliente} className={"mb-2"} alt={"botão adicionar cliente"}/>
               <p>
                  Adicionar<br/>
                  Cliente
               </p>
            </div>
         </div>
      </div>

      <div className={"row"}>
         <Widget
            estatisticas={[{
               label: "Vendas este mês",
               valor: estatisticas.VendasMes,
               variacao: estatisticas.VendasMes - estatisticas.VendasMesAnterior
            }]}
            grafico={[25, 20, 40, 30, 15, 10, 50]}
            cor={"#b66dff"}/>
         <Widget
            estatisticas={[{
               label: "Mais vendido este mês",
               valor: estatisticas.ProdutoMaisVendido?.Nome || "-"
            }]}
            grafico={[25, 20, 40, 30, 15, 10, 50]}
            cor={"#e7bc4b"}/>
         <Widget
            estatisticas={[{
               label: "Novos clientes este mês",
               valor: estatisticas.ClientesMes,
            }]}
            grafico={[25, 20, 40, 30, 15, 10, 50]}
            cor={"#E37576"}
         />
      </div>

      <TabelaHistorico
         titulo={"Histórico de Vendas"}
         colunas={["data", "cliente", "produto", "tipo_movimento", "pontos", "pontos_atuais"]}
      />

      <TabelaClientes
         showNewButton={true}
         exportUrl={`${process.env.REACT_APP_API_URL}/cliente/listar?idClinica=${clinica.id}&exportar_excel`}
      />

      <TabelaProdutos
         colunas={["imagem", "codigo", "nome", "pontos_escalao", "accoes"]}
      />
   </div>
}

export default Dashboard;