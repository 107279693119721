import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require("./0").default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
};

const submeter = async (valores, opcoes) => {
   valores.DataNascimento = [valores.DataNascimentoAno, valores.DataNascimentoMes, valores.DataNascimentoDia].join('-');

   if (opcoes.extra.acao === "editar") {
      let animal = await post(`/cliente/${opcoes.extra.idCliente}/animal/editar`, valores);
      cogoToast.success('Animal editado com sucesso!', toastoptions)

      return animal;
   } else if (opcoes.extra.acao === "adicionar") {
      let animal = await post(`/cliente/${opcoes.extra.idCliente}/animal/adicionar`, valores);
      cogoToast.success('Animal criado com sucesso!', toastoptions)

      return animal;
   }
};

const ClienteAdicionarAnimal = {
   titulo: "Novo animal",
   submeter,
   etapas
};

export default ClienteAdicionarAnimal;