import React from 'react';
import SmsCounter from "sms-counter";

export default ({values, register, errors}) => {

   let numero_mensagens = SmsCounter.count(values.Texto || '');

   return <div className={"NovaCampanhaSMS"}>
      <p className={"mb-4"}>Tenha em atenção o número de caracteres e a utilização de caracteres especiais como acentos (poderá utilizar "é").<br/>
         Mensagens com mais de 160 caracteres ou 70 caracteres (se a mensagem contiver caracteres especiais) serão separadas em 2 ou mais mensagens e cobradas independentemente.<br/>
         O texto que colocou irá utilizar {numero_mensagens.messages} mensage{numero_mensagens.messages === 1 ? "m" : "ns"} ({numero_mensagens.remaining} caracteres restantes).
      </p>
      <div className={"form-group"}>
         <label data-error={errors.Texto?.message}>
            Texto
            <textarea name="Texto" placeholder="Conteúdo da mensagem" type="text" className="form-control form-control-sm" ref={register({
               required: "Por favor insira o texto da mensagem"
            })}/>
         </label>
      </div>
      <div className="form-group">
         <label data-error={errors.Destinatarios?.message}>
            Destinatários
            <select
               name={"Destinatarios"}
               className={"form-control" + (values.Destinatarios ? " ok" : "")}
               ref={register({
                  required: "Por favor indique os destinatários da campanha SMS"
               })}>
               <option value={""} disabled selected>Destinatarios</option>
               <option value={"todos"}>Todos</option>
               <option value={"clientes"}>Clientes</option>
               <option value={"clinicas"}>Clínicas</option>
            </select>
         </label>
      </div>
   </div>
}