import React, {useEffect, useState} from "react";
import ArrowRight from "../../../../assets/images/arrow.svg";
import ProcurarProduto from "../../../ProcurarProduto";
import ProcurarCliente from "../../../ProcurarCliente";

import './styles.scss';
import {get} from "../../../../VirbacProvider";

export default ({values, register, errors, valoresIniciais}) => {
   let produto = typeof values.produtos === 'undefined' ? "" : JSON.parse(values.produtos[0].codigo || '{}');
   let pontosCliente = typeof values.Cliente === 'undefined' ? "" : (JSON.parse(values.Cliente || '{}').Pontos || 0);

   const [escaloes, setEscaloes] = useState([]);

   useEffect(() => {
      (async () => {
         let escaloes = await get("/produto/escalao/listar", {numero_resultados: -1});
         let aux = escaloes.resultados;
         aux.sort((a, b) => b.Pontos - a.Pontos);
         setEscaloes(aux);
      })()
   }, []);

   let tem_pontos_suficientes = values.Cliente && produto && (pontosCliente - produto.PontosEscalao) >= 0;

   return <div className={"NovoDesconto"}>
      <div style={valoresIniciais.Cliente ? {pointerEvents: "none"} : {}}>
         <ProcurarCliente values={values} register={register} errors={errors}/>
      </div>
      <div>
         <div className="form-group mb-1">
            <label>
               Produto
            </label>
         </div>
      </div>

      <div className={"Produto"}>
         <div className="row">
            <div className="col-md">
               <ProcurarProduto {...{
                  errors,
                  values,
                  register,
                  required: true,
                  idxProduto: 0,
                  params: {apenasEscaloesValidos: true}
               }} />
            </div>
         </div>
      </div>

      <div className={"row mb-4 mt-2"}>
         {values.Cliente && produto.PontosEscalao && <div className={"col-md"}>
            {tem_pontos_suficientes ?
               <p className={"m-0"}>Ao resgatar esta oferta, vai <strong>gastar {produto.PontosEscalao} pontos.</strong></p>
               : <p className={"m-0 text-danger"}>Não tem pontos suficientes para usufruir deste desconto.</p>}
         </div>}
      </div>

      {values.Cliente &&
         <div className="row">
            <div className={"col-5 col-md-3 d-flex flex-column mr-2"}>
               <div className={"points-container"}>
                  <p className={"points"}>{pontosCliente}</p>
                  <p className={"points-label"}>Pontos Atuais</p>
               </div>
               <div className="mt-2 badge badge-points primary-color badge-inverse-dark" title={"Escalão Atual"}><i className="badge-icon typcn typcn-gift primary-color mr-1"/>{escaloes.find(e => e.Pontos <= pontosCliente)?.Pontos || 0}</div>
            </div>

            <img src={ArrowRight} className={tem_pontos_suficientes ? "" : "disabled"} alt={""}/>

            <div className={"col-5 col-md-3 d-flex flex-column ml-2 " + (tem_pontos_suficientes ? "" : "disabled")}>
               <div className={"points-container"}>
                  <p className={"points"}>{tem_pontos_suficientes ? pontosCliente - produto.PontosEscalao : "?"}</p>
                  <p className={"points-label"}>Novos Pontos</p>
               </div>
               <div className="mt-2 badge badge-points primary-color badge-inverse-dark" title={"Novo Escalão"}><i className="badge-icon typcn typcn-gift primary-color mr-1"/>{tem_pontos_suficientes ? escaloes.find(e => e.Pontos <= pontosCliente - produto.PontosEscalao)?.Pontos || 0 : "?"}</div>
            </div>
         </div>
      }
   </div>
}