import React, {useEffect, useState} from 'react';
import moment from "moment";
import "moment/locale/pt";

import Noticias from "../../components/Noticias";
import TabelaHistorico from "../../components/TabelaHistorico";
import CardAnimal from "./CardAnimal";
import CardNovoAnimal from "./CardNovoAnimal";
import ProdutosCliente from "./ProdutosCliente";

import {get, useVirbac} from "../../VirbacProvider";
import {useFormulario} from "../../components/Formularios/Provider";
import EditarCliente from '../../assets/images/editar.svg';
import ImagemPontos from './img/fundo_pontos.jpg';

import './styles.scss';

moment.locale("pt");

const DashboardCliente = () => {
   const formulario = useFormulario();
   const virbac = useVirbac();

   const [cliente, setCliente] = useState(null);
   const [noticias, setNoticias] = useState(null);
   const [animaisCliente, setAnimaisCliente] = useState([]);

   const listarAnimais = async () => {
      let animaisCliente = await get(`/cliente/${virbac.utilizador.id}/animal/listar`);
      setAnimaisCliente(animaisCliente);
   }

   const obterCliente = async () => {
      let cliente = await get(`/cliente/${virbac.utilizador.id}`);
      setCliente(cliente);
   }

   useEffect(() => {
      (async () => {
         let noticias = await get("noticia/listar");
         await obterCliente();
         await listarAnimais();

         setNoticias(noticias.resultados)
      })()
   }, []);

   if (!cliente)
      return null;

   if (!noticias)
      return null;

   return <div className={"DashboardCliente"}>
      <div className={"row justify-content-between mb-2 mt-n3"}>
         <div className={"col-md mt-3"}>
            <h2 className={"m-0 font-weight-bold"}>Olá, {cliente.Nome}</h2>
         </div>

         <div className="col-md d-flex mt-3">
            <div className={"ml-auto mr-4"}>
               <p className={"m-0 font-weight-bold"}>Nº cartão</p>
               <p>{cliente.NumeroCartao}</p>
            </div>

            <div>
               <p className={"m-0 font-weight-bold"}>Clínica</p>
               <p>{cliente.Clinica}</p>
            </div>
         </div>
      </div>

      <div className={"row"}>
         <div className={"col-md-6 grid-margin stretch-card"}>
            <div className={"d-flex flex-row card card-padding"}>
               <div className={"col-md-11 p-0"}>
                  <p className={"m-0"}><strong className={"text-uppercase"}>Morada: </strong>{cliente.Morada}</p>
                  <p className={"m-0"}><strong className={"text-uppercase"}>Cod. Postal: </strong>{cliente.CodigoPostal}</p>
                  <p className={"m-0"}><strong className={"text-uppercase"}>Localidade: </strong>{cliente.Localidade}</p>
                  {cliente.Contribuinte && <p className={"m-0"}><strong className={"text-uppercase"}>NIF: </strong>{cliente.Contribuinte}</p>}
                  {cliente.Telefone && <p className={"m-0"}><strong className={"text-uppercase"}>Telefone: </strong>{cliente.Telefone}</p>}
                  {cliente.Email && <p className={""}><strong className={"text-uppercase"}>Email: </strong>{cliente.Email}</p>}
                  {cliente.Observacoes && <p><strong className={"text-uppercase"}>Observações: </strong>{cliente.Observacoes}</p>}
               </div>

               <div className={"col-1 p-0 text-right"}>
                  <img src={EditarCliente} alt={"botao editar cliente"} className={"cursor-pointer"} onClick={() => {
                     let fecharFormularioCliente = formulario.abrirFormulario("editar_cliente", {
                        footerLeft: <a href={"#"} style={{fontSize: "0.625rem"}} className={"primary-color underline"} onClick={() => {
                           formulario.abrirFormulario("eliminar_conta", {
                              extra: {
                                 idCliente: cliente.id
                              },
                              formularioSubmetido: () => {
                                 virbac.logout();
                                 fecharFormularioCliente();
                              }
                           })
                        }}
                        >Eliminar Conta</a>,
                        titulo: "Editar os meus dados",
                        valoresIniciais: {
                           Nome: cliente.Nome,
                           Contribuinte: cliente.Contribuinte,
                           Email: cliente.Email,
                           Telefone: cliente.Telefone,
                           Morada: cliente.Morada,
                           CodigoPostal: cliente.CodigoPostal,
                           Localidade: cliente.Localidade,
                           Observacoes: cliente.Observacoes,
                           MarketingAutorizado: !!cliente.MarketingAutorizado
                        },
                        extra: {
                           acao: "editar_perfil",
                           idCliente: cliente.id
                        },
                        formularioSubmetido: async () => {
                           await obterCliente();
                        }
                     });
                  }}/>
               </div>
            </div>

         </div>

         <div className={"col-md-6 grid-margin stretch-card"}>
            <div style={{backgroundImage: 'linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 80%), url(' + ImagemPontos + ')'}} className={"d-flex justify-content-center align-items-start card card-padding card-pontos"}>
               <div className={"d-flex flex-column align-items-center w-50"}>
                  <h4 className={"m-0"}>TEM</h4>
                  <h2 className={"m-0 pontos-cliente"}>{cliente.Pontos ? cliente.Pontos : 0}</h2>
                  <h2 className={"m-0 font-weight-bold"}>PONTOS</h2>
               </div>
            </div>
         </div>
      </div>

      <div className={"grid-margin"}>
         <Noticias data={noticias}/>
      </div>

      <ProdutosCliente cliente={cliente}/>

      <h2 className={"mb-4 mt-5 font-weight-bold"}>Os meus animais</h2>

      <div className="row mb-n3">
         {animaisCliente.map((animalCliente) => {
            return (
               <CardAnimal
                  tipo={animalCliente.Tipo}
                  nome={animalCliente.Nome}
                  raca={animalCliente.Raca}
                  sexo={animalCliente.Sexo}
                  onClick={() => {
                     let [ano, mes, dia] = animalCliente.DataNascimento.split("-");

                     formulario.abrirFormulario("cliente_adicionar_animal", {
                        titulo: `Editar ${animalCliente.Nome}`,
                        valoresIniciais: {
                           id: animalCliente.id,
                           Tipo: animalCliente.Tipo,
                           Nome: animalCliente.Nome,
                           Raca: animalCliente.Raca,
                           Sexo: animalCliente.Sexo,
                           Esterilizacao: animalCliente.Esterilizacao,
                           DataNascimentoDia: dia,
                           DataNascimentoMes: mes,
                           DataNascimentoAno: ano
                        },
                        extra: {
                           acao: "editar",
                           idAnimal: animalCliente.id,
                           idCliente: cliente.id
                        },
                        formularioSubmetido: async () => {
                           await listarAnimais();
                        }
                     });
                  }}
               />
            )
         })}

         <CardNovoAnimal onClick={() => {
            formulario.abrirFormulario("cliente_adicionar_animal", {
               formularioSubmetido: async () => {
                  await listarAnimais();
               },
               extra: {
                  acao: "adicionar",
                  idCliente: cliente.id
               }
            })
         }}/>
      </div>

      <div className={"mt-4"}>
         <TabelaHistorico
            simpleActions
            colunas={["data", "produto", "tipo_movimento", "pontos", "pontos_atuais"]}
            titulo={"Histórico de compras"}
         />
      </div>

   </div>
};

export default DashboardCliente;