import React from "react";
import moment from "moment";
import "moment/locale/pt";

moment.locale("pt");

export default ({values, register, errors}) => {

   return <div>
      <input type={"hidden"} name={"id"} ref={register}/>
      <div className="row">
         <div className="col-md-4">
            <div className="form-group">
               <label data-error={errors.Tipo?.message}>
                  Espécie de Animal
                  <select
                     name={"Tipo"}
                     className={"form-control" + (values.Tipo ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique o tipo de animal"
                     })}>
                     <option value={""} disabled selected>Espécie</option>
                     <option value={"cao"}>Cão</option>
                     <option value={"gato"}>Gato</option>
                  </select>
               </label>
            </div>
         </div>
         <div className="col-md-8">
            <div className="form-group">
               <label data-error={errors.Nome?.message}>
                  Nome
                  <input
                     ref={register({
                        required: "Por favor indique o nome do animal"
                     })}
                     name={"Nome"}
                     placeholder="Nome"
                     type="text"
                     className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.Raca?.message}>
                  Raça*
                  <input ref={register({
                     required: "Por favor indique a raça do animal"
                  })} name={"Raca"} placeholder="Raça" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-4">
            <div className="form-group">
               <label data-error={errors.Sexo?.message}>
                  Sexo*
                  <select
                     name={"Sexo"}
                     className={"form-control" + (values.Sexo ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique o sexo do animal"
                     })}>
                     <option value={""} disabled selected>Sexo</option>
                     <option value={"m"}>Masculino</option>
                     <option value={"f"}>Feminino</option>
                  </select>
               </label>
            </div>
         </div>
         <div className="col-md-3">
            <div className="form-group">
               <label data-error={errors.Esterilizacao?.message}>
                  Esterilização*
                  <select
                     name={"Esterilizacao"}
                     className={"form-control" + (typeof values.Esterilizacao !== "undefined" && values.Esterilizacao !== "" ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique se o animal é esterilizado ou não"
                     })}>
                     <option value={""} disabled selected>Selecione</option>
                     <option value={"0"}>Não</option>
                     <option value={"1"}>Sim</option>
                  </select>
               </label>
            </div>
         </div>
      </div>

      <div className="form-group" style={{margin: 0}}>
         <label>Data de nascimento*</label>
      </div>

      <div className="row data-nascimento">
         <div className="col-4 col-md-3">
            <div className="form-group">
               <label data-error={errors.DataNascimentoDia?.message}>
                  <select
                     name={"DataNascimentoDia"}
                     className={"form-control" + (values.DataNascimentoDia ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique o dia de nascimento"
                     })}>
                     <option value={""} disabled selected>Dia</option>
                     <option value={0}>Não sei</option>
                     {[...Array(31).keys()].map(i => <option value={i + 1}>{i + 1}</option>)}
                  </select>
               </label>
            </div>
         </div>
         <div className="col-5 col-md-3">
            <div className="form-group">
               <label data-error={errors.DataNascimentoMes?.message}>
                  <select
                     name={"DataNascimentoMes"}
                     className={"form-control" + (values.DataNascimentoMes ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique o mês de nascimento"
                     })}>
                     <option value={""} disabled selected>Mês</option>
                     {[...Array(12).keys()].map(i => {
                        let mes = moment().set('month', i).format("MMMM");
                        return <option value={i + 1}>{mes.charAt(0).toUpperCase() + mes.slice(1)}</option>
                     })}}
                  </select>
               </label>
            </div>
         </div>
         <div className="col-3 col-md-3">
            <div className="form-group">
               <label data-error={errors.DataNascimentoAno?.message}>
                  <select
                     name={"DataNascimentoAno"}
                     className={"form-control" + (values.DataNascimentoAno ? " ok" : "")}
                     ref={register({
                        required: "Por favor indique o ano de nascimento"
                     })}>
                     <option value={""} disabled selected>Ano</option>
                     {[...Array(100).keys()].map(i => {
                        let ano = new Date().getFullYear() - i;
                        return <option value={ano}>{ano}</option>
                     })}
                  </select>
               </label>
            </div>
         </div>
      </div>
   </div>;
}