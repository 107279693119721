import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.utilizador = opcoes.extra.idCliente;
   let eliminacao = await post("/cliente/apagar", valores);
   cogoToast.success('Conta eliminada com sucesso!', toastoptions);

   return eliminacao;
};

const EliminarConta = {
   titulo: "Eliminar conta",

   submeter,
   etapas
};

export default EliminarConta;