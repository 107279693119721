import React, {useEffect, useRef, useState} from 'react';
import RegistarVenda from "../../assets/images/registar_venda.svg";
import RegistarDesconto from "../../assets/images/registar_desconto.svg";
import EditarCliente from "../../assets/images/editar.svg";
import TabelaHistorico from "../../components/TabelaHistorico";
import TabelaAnimais from "./TabelaAnimais";
import TabelaProdutos from "../../components/TabelaProdutos";
import cogoToast from "cogo-toast";

import {get, post, useVirbac} from "../../VirbacProvider";
import {useHistory, useParams} from "react-router-dom";
import {useFormulario} from "../../components/Formularios/Provider";

import './styles.scss';

import GerarPassword from "../../assets/images/gerar_password.svg";

const Cliente = () => {
   const [cliente, setCliente] = useState(null);
   const [escaloes, setEscaloes] = useState([]);
   const virbac = useVirbac();
   const formulario = useFormulario();
   const [desativarReset, setDesativarReset] = useState(false);
   const history = useHistory();
   let {idCliente} = useParams();

   const obterCliente = async () => {
      let cliente = await get(`/cliente/${idCliente}`);
      setCliente(cliente);
   }

   const obterEscaloes = async () => {
      let escaloes = await get("/produto/escalao/listar", {numero_resultados: -1});
      let aux = escaloes.resultados;
      aux.sort((a, b) => b.Pontos - a.Pontos);
      setEscaloes(aux);
   };

   const callbackReloadTabelaHistorico = useRef(null);
   const reloadTabelaHistorico = () => {
      callbackReloadTabelaHistorico.current && callbackReloadTabelaHistorico.current();
   }

   useEffect(() => {
      obterCliente();
      obterEscaloes();
   }, []);

   if (!cliente)
      return null;

   return <div className={"Cliente"}>
      <div className="d-flex justify-content-between mb-4">
         <a href={"#"} className="primary-color font-weight-bold" onClick={(e) => {
            e.preventDefault();
            history.goBack()
         }}>&lt; Voltar</a>

         <div className={"d-flex align-items-center"} style={desativarReset ? {opacity: 0.5, pointerEvents: "none"} : {}}>
            <img className={"mr-3"} src={GerarPassword} alt={"botao gerar password"}/>
            <a href={"#"} style={{whiteSpace: "nowrap"}} className={"primary-color text-uppercase underline"} onClick={async (e) => {
               e.preventDefault();
               setDesativarReset(true);
               await post(`/utilizador/gerar_token`, {IdUtilizador: idCliente});
               cogoToast.success('Pedido de redefinição de password enviado.', {hideAfter: 5, position: 'top-right'});
            }}>Gerar Nova Password</a>
         </div>
      </div>
      <div className={"row"}>
         <div className={"col-md grid-margin stretch-card"}>
            <div className={"info-cliente-container card card-padding d-flex flex-md-row"}>
               <div className={"col-md-7"}>
                  <div className={"d-flex align-items-baseline"}>
                     <h1 className={"mb-3 mr-3 font-weight-bold"}>{cliente.Nome}</h1>
                     <img src={EditarCliente} alt={"botao editar cliente"} className="cursor-pointer" onClick={() => {
                        formulario.abrirFormulario("editar_cliente", {
                           valoresIniciais: {
                              Nome: cliente.Nome,
                              Contribuinte: cliente.Contribuinte,
                              Email: cliente.Email,
                              Morada: cliente.Morada,
                              Telefone: cliente.Telefone,
                              CodigoPostal: cliente.CodigoPostal,
                              Localidade: cliente.Localidade,
                              Observacoes: cliente.Observacoes,
                              MarketingAutorizado: !!cliente.MarketingAutorizado
                           },
                           extra: {
                              acao: "editar_cliente",
                              idCliente: idCliente
                           },
                           formularioSubmetido: async () => {
                              await obterCliente();
                           }
                        })
                     }}/>
                  </div>

                  <div className={"info-cliente"}>
                     <p className={"m-0"}><strong className={"text-uppercase"}>Morada: </strong>{cliente.Morada}</p>
                     <p className={"m-0"}><strong className={"text-uppercase"}>Cod. Postal: </strong>{cliente.CodigoPostal}</p>
                     <p className={"m-0"}><strong className={"text-uppercase"}>Localidade: </strong>{cliente.Localidade}</p>
                     <p className={"m-0"}><strong className={"text-uppercase"}>NIF: </strong>{cliente.Contribuinte || <span className="text-muted">Não introduzido</span>}</p>
                     <p className={"m-0"}><strong className={"text-uppercase"}>Telefone: </strong>{cliente.Telefone || <span className="text-muted">Não introduzido</span>}</p>
                     <p className={""}><strong className={"text-uppercase"}>Email: </strong>{cliente.Email || <span className="text-muted">Não introduzido</span>}</p>
                     <p className={""}><strong className={"text-uppercase"}>Observações: </strong>{cliente.Observacoes || <span className="text-muted">Sem observações</span>}</p>
                     <p className={"m-0"}>Aceita os termos de marketing: {cliente.MarketingAutorizado ? "SIM" : "NÃO"}</p>
                  </div>
               </div>

               <div className={"col-md-5 d-flex flex-column justify-content-between"}>
                  <div className="points-qr d-flex justify-content-center align-items-md-end flex-grow-1 flex-column">
                     <div className="d-flex flex-column">
                        <div className={"points-container"}>
                           <p className={"points"}>{cliente.Pontos || 0}</p>
                           <strong className={"points-label"}>Pontos</strong>
                        </div>
                        <div className="mt-2 badge badge-points primary-color badge-inverse-dark" title={"Escalão"}><i className="badge-icon typcn typcn-gift primary-color mr-1"/>{escaloes.find(e => e.Pontos <= cliente.Pontos)?.Pontos || 0}</div>
                     </div>
                  </div>

                  <div className={"d-flex justify-content-end"}>
                     <div className={"mr-5 card-info clinic-name"}>
                        <strong style={{whiteSpace: "nowrap"}}>Nº cartão</strong>
                        <p className={"m-0"}>{cliente.NumeroCartao}</p>
                     </div>
                     {cliente.Clinica && <div>
                        <strong style={{whiteSpace: "nowrap"}} className={"clinic-name"}>Clínica</strong>
                        <p className={"clinic-name m-0"}>{cliente.Clinica}</p>
                     </div>}
                  </div>
               </div>
            </div>
         </div>

         {virbac.utilizador.Tipo === "clinica" &&
         <div className="col-md-3 d-flex flex-column grid-margin client-button-container">
            <div className={"py-3 card operation-card mb-3 rounded cursor-pointer"} onClick={() => {
               formulario.abrirFormulario("novo_desconto", {
                  valoresIniciais: {
                     Cliente: JSON.stringify(cliente),
                  },
                  formularioSubmetido: () => {
                     obterCliente();
                     reloadTabelaHistorico();
                  }
               });
            }}>
               <img src={RegistarDesconto} className={"mb-2"} alt={"botão registar desconto"}/>
               <p>
                  Registar<br/>
                  Desconto
               </p>
            </div>

            <div className={"py-3 card operation-card rounded cursor-pointer"} onClick={() => {
               formulario.abrirFormulario("nova_venda", {
                  valoresIniciais: {
                     produtos: [{quantidade: 1}],
                     Cliente: JSON.stringify(cliente)
                  },
                  formularioSubmetido: () => {
                     obterCliente();
                     reloadTabelaHistorico();
                  }
               })
            }}>
               <img src={RegistarVenda} className={"mb-2"} alt={"botão registar venda"}/>
               <p>
                  Registar<br/>
                  Venda
               </p>
            </div>
         </div>
         }

      </div>

      <TabelaHistorico
         titulo={"Histórico do Cliente"}
         colunas={["data", "produto", "tipo_movimento", "pontos", "pontos_atuais", ...(virbac.utilizador.Tipo === "admin" ? ["accoes"] : [])]}
         filtro={{idCliente}}
         callbackReload={callbackReloadTabelaHistorico}
         onReload={() => obterCliente()}
      />

      <TabelaAnimais/>

      <TabelaProdutos
         colunas={["imagem", "codigo", "nome", "pontos_escalao", "accoes"]}
         exportUrl={`${process.env.REACT_APP_API_URL}/produto/listar?exportar_excel`}
      />
   </div>

}

export default Cliente;