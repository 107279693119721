import React, {Component, lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import Spinner from './shared/Spinner';

const Widget = lazy(() => import('../components/Widget'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Badges = lazy(() => import('./basic-ui/Badges'));
const Modals = lazy(() => import('./basic-ui/Modals'));
const Popups = lazy(() => import('./basic-ui/Popups'));

const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
const Carousel = lazy(() => import('./advanced-ui/Carousel'));
const Loaders = lazy(() => import('./advanced-ui/Loaders'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));
const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
const Validation = lazy(() => import('./form-elements/Validation'));
const Wizard = lazy(() => import('./form-elements/Wizard'));

const DataTable = lazy(() => import('../components/DataTable'));

const Notifications = lazy(() => import('./notifications/Notifications'));

const TypIcons = lazy(() => import('./icons/TypIcons'));

const Login2 = lazy(() => import('./user-pages/Login2'));

class TemplateRoutes extends Component {
   render() {
      return (
         <Suspense fallback={<Spinner/>}>
            <Switch>
               <Route exact path="/template/widgets" component={Widget}/>

               <Route path="/template/basic-ui/buttons" component={Buttons}/>
               <Route path="/template/basic-ui/badges" component={Badges}/>
               <Route path="/template/basic-ui/modals" component={Modals}/>
               <Route path="/template/basic-ui/popups" component={Popups}/>

               <Route path="/template/advanced-ui/context-menu" component={ContextMenu}/>
               <Route path="/template/advanced-ui/carousel" component={Carousel}/>
               <Route path="/template/advanced-ui/loaders" component={Loaders}/>

               <Route path="/template/form-Elements/basic-elements" component={BasicElements}/>
               <Route path="/template/form-Elements/advanced-elements" component={AdvancedElements}/>
               <Route path="/template/form-Elements/validation" component={Validation}/>
               <Route path="/template/form-Elements/wizard" component={Wizard}/>

               <Route path="/template/tables/data-table" component={DataTable}/>

               <Route path="/template/notifications" component={Notifications}/>

               <Route path="/template/icons/typicons" component={TypIcons}/>

               <Route path="/template/user-pages/login-2" component={Login2}/>
            </Switch>
         </Suspense>
      );
   }
}

export default TemplateRoutes;