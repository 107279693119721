import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores) => {
   let valoresDesconto = {
      Cliente: JSON.parse(valores.Cliente).id,
      Produto: JSON.parse(valores.produtos[0].codigo).id
   }

   let movimento = await post("/movimento/adicionar_desconto", valoresDesconto);

   cogoToast.success('Desconto aplicado com sucesso.', toastoptions)

   return movimento;
}

const NovoDesconto = {
   titulo: "Registar Desconto",
   submeter,
   etapas
}

export default NovoDesconto;