import {serialize} from "object-to-formdata";
import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.id = opcoes.extra.IdNoticia;
   valores.Imagem = valores.Imagem[0];
   valores = serialize(valores);

   if (opcoes.extra.acao === "editar") {
      let noticia = await post(`/noticia/editar`, valores);
      cogoToast.success('Notícia editada com sucesso!', toastoptions);

      return noticia;
   } else if (opcoes.extra.acao === "adicionar") {
      let noticia = await post("/noticia/adicionar", valores);
      cogoToast.success('Notícia criada com sucesso!', toastoptions);

      return noticia;
   }
};

const NovaNoticia = {
   titulo: "Nova notícia",
   submeter,
   etapas
};

export default NovaNoticia;