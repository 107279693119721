import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useFormulario} from "../Formularios/Provider";
import {get} from "../../VirbacProvider";

import './styles.scss';

const ProcurarCliente = ({values, errors, register}) => {
   const {setValue} = useFormContext();

   const [resultados, setResultados] = useState(null);
   const [valorPesquisa, setValorPesquisa] = useState("");
   const formulario = useFormulario();

   useEffect(() => {
      if (!values.Cliente)
         return;
      let cliente = JSON.parse(values.Cliente);
      setValorPesquisa(`${cliente.NumeroCartao} - ${cliente.Nome}`);
   }, [values.Cliente]);

   return <div className={"ProcurarCliente"}>
      <div className="form-group">
         <label data-error={errors.Cliente?.message}>
            Cliente
            <input placeholder="Pesquise por número de cartão, telemóvel, NIF ou e-mail" type="text" className="form-control form-control-sm"
                   value={valorPesquisa}
                   onChange={async e => {
                      let valorPesquisa = e.target.value;
                      setValorPesquisa(valorPesquisa);

                      if (valorPesquisa === '') {
                         setResultados(null);
                         return;
                      }

                      let clientes = await get("/cliente/listar", {
                         numero_resultados: 10,
                         pesquisa: valorPesquisa,
                         procurar_noutras_clinicas: true
                      });
                      setResultados(clientes.resultados)
                   }}
            />
         </label>
         <input name="Cliente" placeholder="ESCONDIDO" type="text" className="d-none" ref={register({
            required: "Por favor indique um cliente"
         })}/>
      </div>
      {resultados && <div className={"resultados_cliente"}>
         {resultados.map(cliente => {
            return <p onClick={() => {
               setValue("Cliente", JSON.stringify(cliente));
               setResultados(null);
            }}>
               {cliente.Nome}<br/>
               <small>
                  {cliente.NumeroCartao}<br/>
                  {cliente.Email}
               </small>
            </p>
         })}

         {resultados.length === 0 && <p
            style={{lineHeight: "30px"}}
            onClick={() => {
               formulario.abrirFormulario("novo_cliente", {
                  formularioSubmetido: async (cliente) => {
                     setValue("Cliente", JSON.stringify(cliente));
                     setValorPesquisa(`${cliente.NumeroCartao} - ${cliente.Nome}`);
                     setResultados(null);
                  }
               })
            }}>
            Não foram encontrados resultados: <a>Criar cliente</a>
         </p>}
      </div>}
   </div>;
}

export default ProcurarCliente;