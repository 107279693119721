import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export default ({register, errors, setValue}) => {
   return <div className={"RecuperarPassword"}>
      <p className={"mb-4"}>Introduza o seu e-mail ou contacto telefónico. Caso o registo seja encontrado iremos enviar uma notificação por e-mail ou SMS com as instruções para recuperação.</p>
      <div className={"form-group"}>
         <label data-error={errors.Contacto?.message}>
            <input name="Contacto" placeholder={"E-mail/contacto telefónico"} type="text" className="form-control form-control-sm" ref={register({
               required: "Por favor introduza o seu e-mail/telemóvel"
            })}/>
         </label>
      </div>
      <label data-error={errors.Captcha?.message}>
         <input type="hidden" name={"Captcha"} ref={register({
            required: "Clique na caixa de verificação"
         })}/>
         <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={result => {
               setValue("Captcha", result);
            }}
         />
      </label>
   </div>
}