import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.id = opcoes.extra.IdEscalao

   if (opcoes.extra.acao === "editar") {
      let escalaoEditado = await post(`/produto/escalao/editar`, valores);
      cogoToast.success('Escalão editado com sucesso!', toastoptions);

      return escalaoEditado
   } else if (opcoes.extra.acao === "adicionar") {
      let escalaoCriado = await post("/produto/escalao/adicionar", valores);
      cogoToast.success('Escalão criado com sucesso!', toastoptions);

      return escalaoCriado;
   }
}

const NovoEscalao = {
   titulo: "Novo escalão",
   submeter,
   etapas
}

export default NovoEscalao;