import React, {useEffect, useState} from 'react';
import DataTable from "../../../components/DataTable";
import {get, post} from "../../../VirbacProvider";
import {useFormulario} from "../../../components/Formularios/Provider";
import cogoToast from "cogo-toast";

import Editar from "../../../assets/images/editar_amarelo.svg";
import Eliminar from "../../../assets/images/icone_eliminar.svg";
import Enviar from "../../../assets/images/enviar.svg";

import {confirmAlert} from "react-confirm-alert";

const TabelaMarketingSMS = (props) => {
   const [campanhas, setCampanhas] = useState(null);
   const formulario = useFormulario();

   let columns = [
      {
         dataField: 'Texto',
         text: 'Texto',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         dataField: 'Destinatarios',
         text: 'Destinatários',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (destinatarios) => {
            return (
               <p className={"m-0 text-capitalize"}>{destinatarios}</p>
            );
         }
      }, {
         dataField: 'Estado',
         text: 'Estado',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         key: "accoes",
         dataField: 'action',
         text: 'Ações',
         sort: false,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, campanha) => {
            if (campanha.DataEnviado) return null;

            return (<div style={{whiteSpace: "nowrap"}}>
               <img className={"cursor-pointer mr-2"} src={Enviar} onClick={() => {
                  confirmAlert({
                     title: `Enviar Campanha?`,
                     message: `Confirma que pretende ativar esta campanha? Prevê-se o envio de ${campanha.NumeroMensagens} mensagens.`,
                     buttons: [
                        {
                           label: 'Sim',
                           onClick: async () => {
                              await post("/marketing/sms/enviar_campanha", {
                                 IdCampanha: campanha.id
                              });
                              cogoToast.success('Campanha ativada com sucesso!', {hideAfter: 5, position: 'top-right'});
                              obterCampanhas();
                           }
                        },
                        {label: 'Cancelar'}
                     ]
                  });
               }}/>
               <img className={"cursor-pointer mr-2"} src={Editar} onClick={() => formulario.abrirFormulario("nova_campanha_sms", {
                  valoresIniciais: campanha,
                  extra: {
                     acao: "editar",
                     IdCampanha: campanha.id
                  },
                  formularioSubmetido: () => {
                     obterCampanhas();
                  },
                  titulo: "Editar Campanha SMS"
               })}/>
               <img className={"cursor-pointer"} src={Eliminar} onClick={() => {
                  confirmAlert({
                     title: `Apagar Campanha?`,
                     message: `Confirma que pretende eliminar esta campanha? Esta ação é irreversível.`,
                     buttons: [
                        {
                           label: 'Sim',
                           onClick: async () => {
                              await post("/marketing/sms/apagar_campanha", {
                                 IdCampanha: campanha.id
                              });
                              obterCampanhas();
                           }
                        },
                        {label: 'Cancelar'}
                     ]
                  });
               }}
               />
            </div>);
         }
      }
   ];

   const obterCampanhas = async () => {
      let campanhas = await get("/marketing/sms/listar_campanhas");
      setCampanhas(campanhas);
   };

   useEffect(() => {
      obterCampanhas();
   }, []);

   if (!campanhas)
      return null;

   return <DataTable
      className={"TabelaMarketingSMS"}
      data={campanhas}
      columns={columns}
      title={"Campanhas de SMS"}
      showNewButton={true}
      newButtonName={"Nova Campanha SMS"}
      newOnClick={() => {
         formulario.abrirFormulario("nova_campanha_sms", {
            formularioSubmetido: () => {
               obterCampanhas();
            },
            extra: {
               acao: "adicionar"
            }
         })
      }}
      exportUrl={`${process.env.REACT_APP_API_URL}/marketing/sms/listar_campanhas?exportar_excel`}
   />
}

export default TabelaMarketingSMS;