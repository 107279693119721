import React from 'react';

import './styles.scss';

import pp20210528 from "./arquivo/virbac_20210528.pdf";
import pp20210510 from "./arquivo/virbac_20210510.pdf";
import pp20210421 from "./arquivo/virbac_20210421.pdf";

const PoliticaPrivacidade = () => {
   return <div className="PoliticaPrivacidade card">
      <div className="card-padding">
         <h3>POLÍTICA DE PRIVACIDADE</h3>

         <p>
            A Virbac encara a sua privacidade e a segurança dos seus dados com grande seriedade.<br/>
            Neste sentido, elaborou a presente Política de Privacidade, com a finalidade de afirmar o seu compromisso e respeito para com as <u>regras de privacidade</u> e de <u>proteção de dados pessoais</u>.<br/>
            Pretendemos que os nossos Clientes conheçam as regras gerais de privacidade e os termos de tratamento dos dados que recolhemos, no estrito respeito e cumprimento da legislação aplicável neste âmbito, nomeadamente do Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016 (“Regulamento Geral sobre a Proteção de Dados” ou “RGPD”).
         </p>

         <h3>DADOS PESSOAIS</h3>
         <p>
            Entende-se por dados pessoais qualquer informação, de qualquer natureza e independentemente do respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular identificada ou identificável (titular dos dados).<br/>
            É considerada identificável a pessoa que possa ser identificada direta ou indiretamente, designadamente por referência a um número de identificação ou a mais elementos específicos da sua identidade física, fisiológica, psíquica, económica, cultural ou social.<br/>
            Os dados pessoais poderão ter uma natureza mais sensível em determinadas situações, classificando-os o RGPD como “categorias especiais de dados”.
         </p>

         <h3>DEFINIÇÕES IMPORTANTES</h3>
         <p>
            Para efeitos desta Política de Privacidade, os conceitos indicados <i>infra</i> terão o significado que lhes é atribuído pelo artigo 4.º do RGPD, os qual prevalece sobre a descrição efetuada <i>infra</i>.<br/>
            <u>Tratamento</u> - operação ou conjunto de operações efetuadas sobre dados pessoais ou sobre conjuntos de dados pessoais, por meios automatizados ou não automatizados, tais como a recolha, o registo, a organização, a estruturação, a conservação, a adaptação ou alteração, a recuperação, a consulta, a utilização, a divulgação por transmissão, difusão ou qualquer outra forma de disponibilização, a comparação ou interconexão, a limitação, o apagamento ou a destruição;<br/>
            <u>Titular dos dados</u> - pessoa singular identificada ou identificável a quem os dados pessoais dizem respeito;<br/>
            <u>Responsável pelo tratamento</u> - pessoa singular ou coletiva, autoridade pública, agência ou outro organismo que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais; sempre que as finalidades e os meios desse tratamento sejam determinados pelo direito da União ou de um Estado-Membro, o responsável pelo tratamento ou os critérios específicos aplicáveis à sua nomeação podem ser previstos pelo direito da União ou de um Estado-Membro;<br/>
            <u>Subcontratante</u> - pessoa singular ou coletiva, autoridade pública, agência ou outro organismo que trate os dados pessoais por conta do responsável pelo tratamento destes;<br/>
            <u>Terceiro</u> - pessoa singular ou coletiva, autoridade pública, serviço ou organismo que não seja o titular dos dados, o responsável pelo tratamento, o subcontratante e as pessoas que, sob a autoridade direta do responsável pelo tratamento ou do subcontratante, estão autorizadas a tratar os dados pessoais;<br/>
            <u>Encarregado da proteção de dados</u> - pessoa ou entidade nomeada para garantir, numa organização, a conformidade do tratamento de dados pessoais com o RGPD, cujo estatuto é por este regulado, assegurando a comunicação eficiente com os titulares dos dados e a cooperação com as autoridades de controlo em causa, fazendo ainda a ponte com as unidades de negócio dentro da organização.<br/>
            O Encarregado da proteção de dados não recebe instruções relativamente ao exercício das suas funções, respondendo diretamente aos órgãos de direção da entidade que o nomeou (responsável pelo tratamento ou do subcontratante);<br/>
            <u>Consentimento do titular dos dados</u> – manifestação de vontade, livre, específica, informada e explícita, pela qual o titular dos dados aceita, mediante declaração ou ato positivo inequívoco, que os dados pessoais que lhe dizem respeito sejam objeto de tratamento;<br/>
            <u>Definição de perfis</u> - qualquer forma de tratamento automatizado de dados pessoais que consista na utilização desses dados pessoais para, nomeadamente, incluir uma pessoa singular em determinada categoria, respeitante ao seu desempenho profissional, à sua situação económica, saúde, preferências pessoais, interesses, fiabilidade, comportamento, localização ou deslocações;<br/>
            <u>Violação de dados pessoais</u> - violação da segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso, não autorizados, a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento;<br/>
            <u>Privacidade desde a conceção</u> – significa incorporar o regime do RGPD em todo o processo de conceção de um novo produto ou serviço, em vez de considerar as questões de privacidade apenas posteriormente. Tal significa avaliar cuidadosamente e implementar medidas e procedimentos técnicos e organizacionais adequados desde o início, em conformidade com o artigo 25.º do RGPD, para garantir que o tratamento está em conformidade com o RGPD e protege os direitos dos titulares dos dados em causa;<br/>
            <u>Privacidade por defeito</u> - significa assegurar que são colocados em prática, dentro de uma organização, mecanismos para garantir que, por defeito, apenas a quantidade necessária de dados pessoais são recolhidos, utilizados e conservados para cada tarefa, tanto em termos da quantidade de dados recolhidos, como do tempo pelo qual eles são mantidos;<br/>
            <u>Autoridade de controlo</u> - uma autoridade pública independente criada por um Estado-Membro, com a responsabilidade pela fiscalização da aplicação do RGPD, a fim de defender os direitos e liberdades fundamentais das pessoas singulares relativamente ao tratamento e facilitar a livre circulação dos dados na União. Em Portugal, a autoridade de controlo será a Comissão nacional de Proteção de Dados (“CNPD”);<br/>
            <u>Transferências internacionais de dados</u> - transferências de dados pessoais que sejam ou venham a ser objeto de tratamento após transferência para um país terceiro (não localizado na União Europeia) ou para uma organização internacional, podendo a transferência ocorrer entre dois ou mais responsáveis pelo tratamento ou entre responsáveis pelo tratamento e subcontratantes;
         </p>

         <h3>RESPONSÁVEL PELO TRATAMENTO DOS SEUS DADOS PESSOAIS</h3>
         <p>
            A presente Política de Privacidade visa dar a conhecer aos Clientes os termos de tratamento de dados pessoais levados a cabo pela Virbac.<br/>
            Nessa medida a Virbac é a entidade Responsável pelo Tratamento, nos termos do RGPD, dos seus dados.
         </p>

         <h3>AS FINALIDADES DA RECOLHA DOS SEUS DADOS PESSOAIS</h3>
         Os dados pessoais dos Clientes são tratados para que estes possam:
         <ol type={"i"}>
            <li>receber informações institucionais do Grupo Virbac;</li>
            <li>receber comunicações informativas e de marketing que sejam considerados relevantes para a promoção dos produtos da Virbac e para a prestação de um serviço de excelência, através dos diferentes canais de comunicação, quer físicos quer digitais, nomeadamente notificações eletrónicas, carta, SMS ou email, ajustadas aos seus interesses e preferências, apurados com base no seu perfil de utilizador, incluindo para envio de informação sobre os produtos, campanhas e serviços Virbac;</li>
            <li>aceder aos benefícios do cartão cliente Virbac, nomeadamente a oferta de produtos associados à acumulação de pontos;</li>
            <li>gerir as suas vendas a clínicas, quando detidas por pessoas singulares;</li>
            <li>participar na realização de inquéritos de avaliação da satisfação dos clientes para nos ajudar a melhorar a prestação de serviços, bem como no âmbito do envio de reclamações e sugestões.</li>
         </ol>

         <p>
            Podemos enviar-lhe comunicações de marketing, caso tenha indicado que as deseja receber.<br/>
            Se aceitar receber comunicações de marketing, enviar-lhe-emos newsletters com notícias nossas, bem como comunicações informativas e de marketing acima referidas que sejam consideradas relevantes para a promoção dos nossos produtos e para a prestação de um serviço de excelência.<br/>
            Tenha em atenção que não partilhamos os seus dados pessoais com outras empresas para efeitos de marketing, <u>exceto se tivermos o seu consentimento para o efeito</u>.<br/>
            Caso não queira receber mais comunicações de marketing da nossa parte, <u>basta <strong>aceder ao seu perfil e alterar as definições das comunicações no formulário de edição</strong> ou enviar-nos um email para <a target={"_blank"} href={"mailto:info_RGPD@virbac.pt"}>info_RGPD@virbac.pt</a></u><br/>
            Também podemos recolher informações sobre como utiliza o nosso <i>website</i>, de modo a compreender os seus interesses. Podemos utilizar estas informações para adaptar o conteúdo e ofertas que vê no nosso <i>website</i> e, se tiver concordado em receber comunicações de marketing, enviar-lhe mensagens relevantes que pensamos que irá gostar.
         </p>

         <h3>AS CATEGORIAS DOS SEUS DADOS PESSOAIS TRATADAS</h3>

         <p>Para as finalidades indicadas supra, a Virbac trata as seguintes categorias de dados pessoais:</p>

         <p><u>Clientes</u> - proprietários de animais</p>
         <ul>
            <li>Nome Completo</li>
            <li>Morada</li>
            <li>Código Postal</li>
            <li>Localidade</li>
            <li>Telefone</li>
            <li>E-mail</li>
            <li>Poderão ser ainda tratadas as seguintes categorias relativas aos animais dos seus utilizadores, nomeadamente a espécie, nome, raça, sexo, data de Nascimento, bem como estado de Esterilização.</li>
         </ul>

         <p><u>Parceiros</u> – clínicas veterinárias, quando detidas por pessoas singulares</p>
         <ul>
            <li>Nome Comercial</li>
            <li>Designação Social</li>
            <li>Morada</li>
            <li>Código Postal</li>
            <li>Localidade</li>
            <li>Telefone</li>
            <li>E-mail</li>
            <li>NIF</li>
         </ul>

         <h3>FUNDAMENTO PARA TRATAMENTO DOS SEUS DADOS PESSOAIS</h3>

         <p>
            A Virbac apenas tratará os seus dados pessoais quando esteja devidamente habilitada para o fazer.<br/>
            O RGPD exige, para que o tratamento de dados pessoais seja lícito, que exista um fundamento de licitude adequado para cada tratamento específico. Tais fundamentos poderão ser de índole variada.<br/>
            Assim, o tratamento de dados pessoais poderá ter por base o consentimento do titular dos dados, a execução de um contrato no qual o titular dos dados seja parte, o cumprimento de obrigações jurídicas a que o Responsável pelo Tratamento esteja sujeito, a defesa de interesses vitais do titular dos dados ou, ainda, a prossecução de interesses legítimos do Responsável pelo Tratamento (exceto se prevalecerem os interesses e liberdades fundamentais do titular).<br/>
            Os seus dados pessoais serão assim recolhidos para fins de marketing e comunicação (comunicação de informação, produtos e ofertas do seu interesse e para personalizar e melhorar a sua experiência enquanto cliente do grupo Virbac) nos termos do formulário de subscrição da newsletter constante na página inicial do <i>website</i>, sendo o fundamento jurídico aplicável para a recolha e utilização dos dados pessoais para fins de marketing e comunicação <u>o seu consentimento enquanto titular dos dados</u>. Ou seja, o fundamento de licitude no qual a Virbac assenta tais tratamentos de dados será o consentimento dos titulares dos dados, ou seja, dos seus Clientes.<br/>
            Nos termos do RGPD, o titular dos dados tem o direito de retirar o seu consentimento a qualquer momento, não comprometendo a retirada do consentimento a licitude do tratamento efetuado com base no consentimento previamente dado.<br/>
            Relativamente aos tratamentos dos seus dados efetuados pela Virbac para melhorar os nossos serviços e cumprir os nossos objetivos administrativos e comerciais, o fundamento de licitude adequado será a prossecução de interesses legítimos da entidade Responsável pelo Tratamento, a Virbac. Tal implica que os titulares dos dados possam opor-se ao tratamento dos seus dados para os efeitos acima referidos, ao abrigo do RGPD, caso apresentem motivos válidos relacionados com a sua situação particular. Em tal eventualidade, o Responsável pelo Tratamento poderá apresentar razões imperiosas e legítimas que justifiquem a continuação desse tratamento, caso em que se reserva o direito de continuar a tratar os seus dados para esses efeitos, tal como nos casos em que tal tratamento seja necessário para efeitos de declaração, exercício ou defesa de um direito num processo judicial.<br/>
            No âmbito do tratamento dos seus dados pessoais, a Virbac observa, a todo o tempo, os princípios da proteção de dados desde a conceção e por defeito. Tal compromisso implica, entre outros aspetos, que os seus dados pessoais serão de acesso limitado às pessoas que tenham necessidade de os conhecer no exercício das suas funções, no estrita medida do necessário para a prossecução das finalidades de tratamento que já referimos acima.
         </p>

         <h3>PERÍODO DE CONSERVAÇÃO DOS SEUS DADOS PESSOAIS</h3>
         <p>
            Os dados pessoais dos Clientes que a Virbac recolhe são tratados no estrito cumprimento da legislação aplicável, sendo armazenados em base de dados específicas, criadas para o efeito.<br/>
            Tais dados são conservados num formato que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados.<br/>
            O período de tempo durante o qual os dados são armazenados e conservados varia de acordo com a finalidade para a qual a informação é utilizada. Existem, no entanto, requisitos legais que obrigam a conservar os dados por um determinado período de tempo.
         </p>

         <h3>DIREITOS DOS TITULARES DOS DADOS</h3>
         <p>
            Nos termos da legislação aplicável, o titular dos dados poderá solicitar, a todo o tempo, o acesso aos dados pessoais que lhe digam respeito, bem como a sua retificação, eliminação ou a limitação do seu tratamento, a portabilidade dos seus dados, ou opor-se ao seu tratamento, diretamente através do nosso <i>website</i>.<br/>
            Poderá obter a confirmação dos dados pessoais que lhe dizem respeito que são objeto de tratamento, bem como o acesso aos mesmos, sendo-lhe disponibilizada, caso requeira e não existam restrições legais, uma cópia dos dados objeto de tratamento por parte da Virbac.<br/>
            Neste sentido, o direito de acesso aos seus dados pessoais não é irrestrito, podendo a Virbac recusar fornecer-lhe uma cópia dos seus dados pessoais em fase de tratamento se o seu acesso prejudicar os direitos e as liberdades de terceiros, inclusivamente da Virbac. Por essa via poderiam, por exemplo, ser revelados segredos de negócio da Virbac ou serem violados direitos de propriedade intelectual da mesma. Nesses casos, a entidade Responsável pelo Tratamento poderá solicitar-lhe que especifique a que informações ou a que atividades de tratamento se refere o seu pedido de acesso, para que aquela possa prestar-lhe as informações solicitadas.<br/>
            Nos termos da lei, é-lhe ainda garantido o direito de, através dos meios acima referidos, retirar o seu consentimento para os tratamentos de dados relativamente aos quais o consentimento constitui o fundamento de legitimidade. Para o efeito, tem o direito de retirar o seu consentimento a qualquer momento, o que não invalida, no entanto, o tratamento efetuado até essa data com base no consentimento previamente dado.<br/>
            Relativamente ao tratamento dos seus dados para efeito do envio de comunicações de marketing direto, a Virbac disponibilizar–lhe-á um link, em cada uma dessas comunicações, através do qual poderá retirar o seu consentimento.<br/>
            O cliente ou utilizador pode ainda, a qualquer momento, solicitar a eliminação dos seus dados pessoais, nos termos legalmente previstos.<br/>
            O Cliente tem, também, o direito de, nos termos da legislação aplicável, solicitar a limitação do tratamento, de se opor ao tratamento ou de obter a portabilidade dos seus dados, verificadas as condições legalmente previstas. Para o efeito, deverá submeter <strong>um pedido à Virbac através do respectivo <i>website</i></strong>.<br/>
            Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, o titular dos dados tem direito a apresentar uma reclamação à CNPD ou a outra autoridade de controlo competente nos termos da lei, caso considere que os seus dados não estão a ser objeto de tratamento legítimo por parte da Virbac, nos termos da legislação aplicável e da presente Política.
         </p>

         <h3>UTILIZADORES DAS PLATAFORMAS DA VIRBAC</h3>
         <p>
            A presente Política de Privacidade aplica-se integralmente a todos os utilizadores de todas as Plataformas do Grupo Virbac.<br/>
            No entanto, dada a especificidade inerente à utilização das referidas plataformas digitais, importa regular algumas questões particularmente relevantes neste âmbito.<br/>
            O Grupo Virbac tem consciência de que o envio de informação pessoal é uma grande preocupação para os Clientes que utilizam a Internet. Assim, no nosso <i>website</i> e nas nossas aplicações, os formulários de recolha de dados pessoais obrigam a sessões encriptadas do browser, e todos os dados pessoais que nos disponibiliza ficam armazenados de forma segura nos sistemas do Grupo Virbac, sobre os quais são implementadas as melhores práticas de segurança técnicas e processuais visando a proteção dos seus dados pessoais.<br/>
            Não obstante as medidas de segurança adotadas pelo Grupo Virbac, alertamos todos os nossos Clientes e utilizadores que aquando do acesso à Internet devem tomar regularmente precauções e adotar medidas adicionais de segurança neste âmbito, designadamente através da utilização de um computador e um <i>browser</i> atualizados, e acautelar o uso de computadores partilhados, bem como o acesso às suas contas pessoais de Cliente, não partilhando com terceiros os seus dados de acesso.<br/>
            Através das nossas Plataformas, disponibilizamos ligações para <i>websites</i> de terceiros, os quais estão sujeitos a Políticas de Privacidade independentes. Tenha em consideração que a presente Política de Privacidade não se aplica a tais <i>websites</i> e que as entidades do Grupo Virbac não são responsáveis pela recolha de informações suas por parte dos referidos terceiros através dos respetivos <i>websites</i>.
         </p>

         <h3>MEDIDAS DE SEGURANÇA ADOTADAS PELA VIRBAC</h3>
         <p>
            O Grupo Virbac está empenhado em assegurar a confidencialidade, proteção e segurança dos dados pessoais dos seus Clientes, através da implementação das medidas técnicas e organizativas adequadas para proteger os seus dados contra qualquer forma de tratamento indevido ou ilegítimo e contra qualquer perda acidental ou destruição destes dados.<br/>
            Para o efeito, dispomos de sistemas e equipas destinados a garantir a segurança dos dados pessoais tratados, criando e atualizando procedimentos que previnam acessos não autorizados, perdas acidentais e/ ou destruição dos dados pessoais, comprometendo-se a respeitar a legislação relativa à proteção de dados pessoais dos Clientes e a tratar estes dados apenas para os fins para que foram recolhidos, assim como a garantir que estes dados são tratados com adequados níveis de segurança e confidencialidade.
         </p>

         <h3>CONTACTE-NOS</h3>
         <p>
            Poderá contactar o Encarregado de Proteção de Dados da Virbac Portugal para mais informações sobre o tratamento dos seus dados pessoais, bem como quaisquer questões relacionadas com o exercício dos direitos que lhe são atribuídos pela legislação aplicável e, em especial, os referidos na presente Política de Privacidade, através dos seguintes contactos:<br/>
            <table>
               <tr>
                  <td>e-mail:</td>
                  <td>
                     <strong>info_RGPD@virbac.pt</strong>
                  </td>
               </tr>
               <tr>
                  <td>Morada:</td>
                  <td>
                     <strong>Rua do Centro Empresarial, edifício 13 – piso 1 – escritório 3</strong>
                  </td>
               </tr>
               <tr>
                  <td/>
                  <td>
                     <strong>2710-693 Sintra</strong>
                  </td>
               </tr>
            </table>
         </p>

         <h3>ACTUALIZAÇÕES DA PRESENTE POLÍTICA DE PRIVACIDADE</h3>
         <p>
            Note que a presente Política de privacidade pode ser alterada periodicamente. Qualquer alteração à Política de privacidade do Grupo VIRBAC será publicada nesta página. Manteremos igualmente versões anteriores desta Política de privacidade num arquivo para consulta.<br/>
            Esta política de privacidade foi atualizada em: 28 de maio de 2021
         </p>

         <p>Arquivo de alterações:</p>

         <ul>
            <li><a href={pp20210528} target="_blank">28 de maio de 2021</a></li>
            <li><a href={pp20210510} target="_blank">10 de maio de 2021</a></li>
            <li><a href={pp20210421} target="_blank">21 de abril de 2021</a></li>
         </ul>
      </div>
   </div>
}

export default PoliticaPrivacidade;