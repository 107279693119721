import React from "react";

export default ({values, register, errors}) => {
   return <div className={"NovoDelegado"}>
      <div className={"row"}>
         <div className="col-md">
            <div className="form-group">
               <label data-error={errors.Nome?.message}>
                  Nome Completo
                  <input ref={register({
                     required: "Por favor indique o nome completo do delegado"
                  })} name="Nome" placeholder="Nome Completo" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>

      <div className={"row"}>
         <div className="col-md">
            <div className="form-group">
               <label data-error={errors.Email?.message}>
                  Email
                  <input ref={register({
                     required: "Por favor indique o email do delegado"
                  })} name="Email" placeholder="Email" type="email" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>

      <div className={"row"}>
         <div className="col-md">
            <div className="form-group">
               <label data-error={errors.Telefone?.message}>
                  Telemóvel
                  <input ref={register({
                     required: "Por favor indique o telemóvel do delegado"
                  })} name="Telefone" placeholder="Telemóvel" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>

   </div>
}