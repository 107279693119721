import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {get, post, useVirbac} from "../../VirbacProvider";
import cogoToast from "cogo-toast";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

import GerarPassword from "../../assets/images/gerar_password.svg";

import TabelaHistorico from "../../components/TabelaHistorico";
import TabelaClientes from "../../components/TabelaClientes";

import "./styles.scss";

const Clinica = () => {
   const virbac = useVirbac();
   const [clinica, setClinica] = useState(null);
   const history = useHistory();
   const [desativarReset, setDesativarReset] = useState(false);
   let {idClinica} = useParams();
   const {isLoaded} = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
   });
   const [coordinates, setCoordinates] = useState(null);

   useEffect(() => {
      if (!isLoaded || !clinica)
         return;
      (async () => {
         let geocoder = new window.google.maps.Geocoder();
         geocoder.geocode({
            address: clinica.Morada,
            componentRestrictions: {
               country: 'PT',
               postalCode: clinica.CodigoPostal
            }
         }, (result) => {
            if (result.length === 0)
               return;
            let coordenadas = result[0].geometry.location;
            setCoordinates(coordenadas);
         });
      })()

   }, [isLoaded, clinica]);

   useEffect(() => {
      (async () => {
         let clinica = await get(`/clinica/${idClinica}`);
         setClinica(clinica);
      })()
   }, []);

   if (!clinica)
      return null;

   return <div className={"Clinica"}>
      <div className="d-flex justify-content-between mb-4">
         <a href={"#"} className="primary-color font-weight-bold" onClick={(e) => {
            e.preventDefault();
            history.goBack()
         }}>&lt; Voltar</a>

         <div className={"d-flex align-items-center"} style={desativarReset ? {opacity: 0.5, pointerEvents: "none"} : {}}>
            <img className={"mr-3"} src={GerarPassword} alt={"botao gerar password"}/>
            <a href={"#"} style={{whiteSpace: "nowrap"}} className={"primary-color text-uppercase underline"} onClick={async (e) => {
               e.preventDefault();
               setDesativarReset(true);
               await post(`/utilizador/gerar_token`, {IdUtilizador: idClinica});
               cogoToast.success('Pedido de redefinição de password enviado.', {hideAfter: 5, position: 'top-right'});
            }}>Gerar Nova Password</a>
         </div>
      </div>

      <div className={"card grid-margin detalhes"}>
         {coordinates && <GoogleMap
            mapContainerClassName={"mapa"}
            center={coordinates}
            zoom={15}
            options={{
               disableDefaultUI: true
            }}
         >
            {coordinates && <Marker position={coordinates}/>}
         </GoogleMap>}

         <div className={"row card-padding pt-4 pb-5"}>
            <div className={"col-md"}>
               <h1 className={"mb-3 font-weight-bold"}>{clinica.NomeComercial}</h1>
               <p className={"mb-1"}><strong className={"text-uppercase"}>Designação Social: </strong>{clinica.DesignacaoSocial}</p>
               <p className={"mb-1"}><strong className={"text-uppercase"}>Morada: </strong>{clinica.Morada}</p>
               <p className={"mb-1"}><strong className={"text-uppercase"}>Cod. Postal: </strong>{clinica.CodigoPostal}</p>
               <p className={"mb-1"}><strong className={"text-uppercase"}>Localidade: </strong>{clinica.Localidade}</p>
               <p className={"mb-1"}><strong className={"text-uppercase"}>NIF: </strong>{clinica.Contribuinte}</p>
               <p className={"mb-1"}><strong className={"text-uppercase"}>Telefone: </strong>{clinica.Telefone}</p>
               <p><strong className={"text-uppercase"}>Email: </strong>{clinica.Email}</p>
            </div>
         </div>
      </div>

      {virbac.utilizador.Tipo === "admin" && <TabelaClientes
         exportUrl={`${process.env.REACT_APP_API_URL}/cliente/listar?idClinica=${clinica.id}&exportar_excel&apenas_registos_clinica`}
         filtro={{idClinica, apenas_registos_clinica: true}}
      />}

      <TabelaHistorico
         titulo={"Histórico de vendas"}
         colunas={["data", "produto", "cliente", "pontos", "tipo_movimento", "accoes"]}
         filtro={{idClinica}}
      />
   </div>
};

export default Clinica;