import React from 'react';
import TabelaDelegados from "./TabelaDelegados";

const Delegados = () => {

   return <div className={"Delegados"}>
      <TabelaDelegados/>
   </div>
};

export default Delegados;