import React, {useEffect, useState} from 'react';

import ArrowRight from '../../../../assets/images/arrow.svg';
import {get} from "../../../../VirbacProvider";

export default ({values, register, errors}) => {
   const [escaloes, setEscaloes] = useState([]);

   useEffect(() => {
      (async () => {
         let escaloes = await get("/produto/escalao/listar", {numero_resultados: -1});
         let aux = escaloes.resultados;
         aux.sort((a, b) => b.Pontos - a.Pontos);
         setEscaloes(aux);
      })()
   }, []);

   if (!values.Cliente)
      return null;

   let cliente = JSON.parse(values.Cliente);

   let produtos = values.produtos ? values.produtos.map(({quantidade, codigo}) => {
      if (!codigo)
         return null;
      let produto = JSON.parse(codigo);
      return {
         quantidade,
         produto
      }
   }).filter(p => p) : [];

   let novos_pontos = (cliente.Pontos || 0) + produtos.map(produto => produto.quantidade * produto.produto.Pontos).reduce((a, b) => a + b, 0);

   return <div className={"NovaVenda"}>
      <p className="font-weight-bold">{cliente.Nome}</p>
      <p className={"m-0"}><strong className={"text-uppercase"}>Nif </strong>{cliente.Contribuinte}</p>
      <p><strong className={"text-uppercase"}>Nº Cartão </strong>{cliente.NumeroCartao}1000</p>

      {produtos.map(({quantidade, produto}) => {
         return <div className="mb-3">
            <strong>
               {quantidade} x {produto.Codigo} - {produto.Nome}
               <span className={"ml-3 badge badge-points primary-color badge-inverse-success"}>+{produto.Pontos * quantidade} pontos</span>
            </strong>
         </div>;
      })}

      <div className="row">
         <div className={"col-5 col-md-3 d-flex flex-column mr-2"}>
            <div className={"points-container"}>
               <p className={"points"}>{cliente.Pontos || 0}</p>
               <p className={"points-label"}>Pontos Atuais</p>
            </div>
            <div className="mt-2 badge badge-points primary-color badge-inverse-dark" title={"Escalão Atual"}><i className="badge-icon typcn typcn-gift primary-color mr-1"/>{escaloes.find(e => e.Pontos <= cliente.Pontos)?.Pontos || 0}</div>
         </div>

         <img src={ArrowRight} alt={""}/>

         <div className={"col-5 col-md-3 d-flex flex-column ml-2"}>
            <div className={"points-container"}>
               <p className={"points"}>{novos_pontos}</p>
               <p className={"points-label"}>Novos Pontos</p>
            </div>
            <div className="mt-2 badge badge-points primary-color badge-inverse-dark" title={"Novo Escalão"}><i className="badge-icon typcn typcn-gift primary-color mr-1"/>{escaloes.find(e => e.Pontos <= novos_pontos)?.Pontos || 0}</div>
         </div>
      </div>
   </div>
}