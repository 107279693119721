import React from "react";
import ProcurarProduto from "../../../ProcurarProduto";
import ProcurarCliente from "../../../ProcurarCliente";

export default ({values, register, errors}) => {

   return <div className={"EditarMovimento"}>
      <div style={{pointerEvents: "none"}}>
         <ProcurarCliente values={values} register={register} errors={errors}/>
      </div>

      <div>
         <div className="form-group mb-1">
            <label>
               Produto
            </label>
         </div>
      </div>

      <div className={"Produto"}>
         <div className="row">
            <div className="col-3">
               <div className="form-group mb-0">
                  <label data-error={errors.produto && errors.produto?.quantidade?.message}>
                     <select
                        ref={register}
                        name={"produtos[0].quantidade"}
                        className={"form-control" + (values.produtos && values.produtos[0]?.quantidade ? " ok" : "")}>
                        <option value={""} disabled selected>Quantidade</option>
                        {[...Array(21).keys()].map(i => {
                           return <option value={i}>{i}</option>;
                        })}
                     </select>
                  </label>
               </div>
            </div>
            <div className="col-9">
               <ProcurarProduto {...{
                  errors,
                  values,
                  register,
                  idxProduto: 0
               }} />
            </div>
         </div>
      </div>
   </div>
}