import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require("./0").default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   if (opcoes.extra.acao === "adicionar") {
      let delegado = await post("/delegado/adicionar", valores);
      cogoToast.success('Delegado adicionado com sucesso.', toastoptions);

      return delegado;
   } else if (opcoes.extra.acao === "editar") {
      valores.id = opcoes.extra.IdDelegado;

      let delegado = await post("/delegado/editar", valores);
      cogoToast.success('Delegado editado com sucesso.', toastoptions);

      return delegado;
   }
};

const NovoDelegado = {
   titulo: "Novo Delegado",
   submeter,
   etapas
};

export default NovoDelegado;