import React from "react";
import verificarNif from "../../../../functions/verificarNif";

export default ({values, register, errors}) => {
   return <div className={"NovoCliente"}>
      <div className="row">
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Nome?.message}>
                  Nome Completo*
                  <input ref={register({
                     required: "Por favor indique o nome completo do cliente"
                  })} name="Nome" placeholder="Nome Completo" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Contribuinte?.message}>
                  Contribuinte*
                  <input ref={register({
                     required: "Por favor indique o contribuinte",
                     validate: (valor) => verificarNif(valor) ? true : "O número de contribuinte não é válido"
                  })} name="Contribuinte" placeholder="Contribuinte" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Email?.message}>
                  Email
                  <input ref={register({
                     required: values.Telefone ? false : "Por favor indique o email do cliente",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "O email não é válido"
                     }
                  })} name="Email" placeholder="Email" type="email" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Telefone?.message}>
                  Telemóvel
                  <input ref={register({
                     required: values.Email ? false : "Por favor indique o telemóvel",
                  })} name="Telefone" placeholder="Telemóvel" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="form-group">
         <label data-error={errors.Morada?.message}>
            Morada*
            <input ref={register({
               required: "Por favor indique a morada"
            })} name="Morada" placeholder="Morada" type="text" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="row">
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.CodigoPostal?.message}>
                  Código Postal*
                  <input ref={register({
                     required: "Por favor indique o código postal"
                  })} name="CodigoPostal" placeholder="Código Postal" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-5">
            <div className="form-group">
               <label data-error={errors.Localidade?.message}>
                  Localidade*
                  <input ref={register({
                     required: "Por favor indique a localidade"
                  })} name="Localidade" placeholder="Localidade" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="form-group">
         <label data-error={errors.Observacoes?.message}>
            Observações
            <textarea ref={register} name="Observacoes" placeholder="Observações" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="form-check mt-3">
         <label className="form-check-label label-checkbox" data-error={errors.BeneficiosAutorizado?.message}>
            <input name="BeneficiosAutorizado" type="checkbox" className="form-check-input"
                   ref={register({
                      required: "Por favor confirme o consentimento"
                   })}/>
            <i className="input-helper"></i>
            O cliente confirma ter conhecimento de que a utilização dos seus dados tem como finalidade o acesso aos benefícios do Cartão Cliente Virbac, nomeadamente a oferta de produtos associados à acumulação de pontos.
         </label>
      </div>
      <div className="form-check mt-3">
         <label className="form-check-label label-checkbox" data-error={errors.MarketingAutorizado?.message}>
            <input name="MarketingAutorizado" type="checkbox" className="form-check-input" ref={register}/>
            <i className="input-helper"></i>
            O cliente consente que os seus dados pessoais sejam utilizados para enviar informação sobre os produtos, campanhas, serviços Virbac.
         </label>
      </div>
   </div>;
}