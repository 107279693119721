import React from 'react';

export default ({values, register, errors}) => {

   return <div className={"NovoEscalao"}>
      <p>Para atualizar a informação sobre o PVP de produto recomendado, submeta um ficheiro excel com a indicação do código dos produtos e respetivo valor. Poderá fazer a exportação da listagem de produtos <a href={process.env.REACT_APP_API_URL + "/produto/listar?exportar_excel"} target="_blank">(aqui)</a>, editar o campo PVP e submeter o ficheiro editado através deste formulário.</p>
      <div className={"form-group"}>
         <label data-error={errors.Ficheiro?.message}>
            Ficheiro Excel
            <input name="Ficheiro" placeholder="Ficheiro" type="file" className="form-control form-control-sm" ref={register({
               required: "Por favor selecione o ficheiro"
            })}/>
         </label>
      </div>
   </div>
}