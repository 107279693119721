import React, {useState} from 'react';
import {Button, Form, FormGroup} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {useVirbac} from "../../VirbacProvider";
import cogoToast from "cogo-toast";

import './styles.scss'

import Visualizar from "../../assets/images/visualizar_icon.svg";
import Esconder from "../../assets/images/esconder_icon.svg";
import {useFormulario} from "../../components/Formularios/Provider";

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const Login = () => {
   const {register, handleSubmit, setError, errors} = useForm();
   const [mostrarPassword, setMostrarPassword] = useState(false);
   const virbac = useVirbac();
   const formulario = useFormulario();

   const onSubmit = async data => {
      await virbac.login(data).then(() => {
         cogoToast.success('Fez login com sucesso.', toastoptions);
      }).catch(function (error) {
         if (error.response.data.code === 401) {
            setError("button", {
               type: "server",
               message: error.response.data.message
            });
         }
      });
   };

   return <div className={"Login"}>
      <div className={"card pl-5 pr-5 pt-4 pb-4"}>
         <h1 className={"mb-4 font-weight-bold text-center primary-color"}>Entrar</h1>

         <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className={"mb-3"}>
               <Form.Control name={"utilizador"} placeholder={"E-mail ou Telemóvel"} ref={register({
                  required: true
               })}/>
               {errors.email?.type === "required" && <p className={"error-message"}>O utilizador é obrigatório. Coloque o seu e-mail ou telemóvel.</p>}
            </FormGroup>

            <Form.Group className={"m-0 password"} controlId={"password"}>
               <Form.Control name={"password"} type={mostrarPassword ? "text" : "password"} placeholder={"Password"} ref={register({
                  required: true
               })}/>
               <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
               {errors.password && <p className={"error-message"}>A password é obrigatória</p>}
            </Form.Group>
            <div className={"mb-3 mt-3 text-center"}>
               <a className={"primary-color underline"} href={"#"} onClick={e => {
                  e.preventDefault();
                  formulario.abrirFormulario("recuperar_password");
               }}>Recuperar a senha</a>
            </div>
            <div className={"text-center mt-4"}>
               <Button name={"button"} className={"w-50 pt-2 pb-2"} variant="login" type={"submit"} ref={register}>
                  Entrar
               </Button>
               {errors.button?.type === "server" && <p className={"mb-0 error-message"}>{errors.button?.message}</p>}
            </div>
         </Form>
      </div>
   </div>
}

export default Login;