import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";
import {serialize} from "object-to-formdata";

const etapas = [{
   titulo: "",
   formulario: require('./0').default
}];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
};

const submeter = async (valores) => {
   let formData = serialize(valores, {indices: true});
   await post("/produto/importar_pvp", formData);
   cogoToast.success('Informação de PVP atualizada com sucesso', toastoptions);
};

const ImportarPVP = {
   titulo: "Importar tabela PVP",
   submeter,
   etapas
}

export default ImportarPVP;