import React from 'react';
import './App.scss';
import TemplateRoutes from './template/TemplateRoutes';
import Navbar from './template/shared/Navbar';
import Footer from "./components/Footer";
import Routes from "./Routes";
import VirbacProvider from "./VirbacProvider";
import FormularioProvider from "./components/Formularios/Provider";

const App = () => {
   return <VirbacProvider>
      <FormularioProvider>
         <div className="container-scroller">
            <Navbar/>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
               <div className="main-panel">
                  <div className="content-wrapper">
                     <TemplateRoutes/>
                     <Routes/>
                  </div>
               </div>
            </div>
            <Footer/>
         </div>
      </FormularioProvider>
   </VirbacProvider>;
};

export default App;
