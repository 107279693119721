import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useFormulario} from "../Formularios/Provider";
import {get} from "../../VirbacProvider";

import './styles.scss';

const ProcurarProduto = ({errors, register, values, idxProduto, required, params = {}}) => {
   const {setValue} = useFormContext();

   const [resultados, setResultados] = useState(null);
   const [valorPesquisa, setValorPesquisa] = useState("");
   const formulario = useFormulario();

   useEffect(() => {
      if (!values.produtos || !values.produtos[idxProduto] || !values.produtos[idxProduto].codigo)
         return;
      let produto = JSON.parse(values.produtos[idxProduto].codigo);
      setValorPesquisa(`${produto.Nome}`);
   }, [values.produtos && values.produtos[idxProduto]]);

   return <div className={"ProcurarProduto"}>
      <div className="form-group mb-0">
         <label data-error={errors.produtos && errors.produtos[idxProduto]?.codigo?.message}>
            <input placeholder="Produto" type="text" className="form-control form-control-sm"
                   value={valorPesquisa}
                   onChange={async e => {
                      let valorPesquisa = e.target.value;
                      setValorPesquisa(valorPesquisa);

                      if (valorPesquisa === '') {
                         setResultados(null);
                         return;
                      }

                      let produtos = await get("/produto/listar", {
                         numero_resultados: -1,
                         pesquisa: valorPesquisa,
                         ...params
                      });
                      setResultados(produtos.resultados)
                   }}
            />
         </label>
         <input
            name={"produtos[" + idxProduto + "].codigo"}
            placeholder="ESCONDIDO"
            type="text"
            className="d-none"
            ref={register({
               required: required || (values.produtos && values.produtos[idxProduto]?.quantidade > 0) ? "Por favor selecione o produto" : false
            })}/>
      </div>
      {resultados && <div className={"resultados-produto"}>
         {resultados.map(produto => {
            return <div className={"resultado-produto d-flex align-items-center"}>
               <div style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/public/produtos/${produto.Imagem}_thumb.jpg)`}} className={"product-image"}/>
               <p onClick={() => {
                  setValue("produtos[" + idxProduto + "].codigo", JSON.stringify(produto));
                  setValorPesquisa(`${produto.Nome}`);
                  setResultados(null);
               }}>
                  {produto.Nome}<br/>
                  <small>
                     Referência: {produto.Codigo}
                  </small>
               </p>
            </div>
         })}

         {resultados.length === 0 && <p
            style={{lineHeight: "30px", margin: 0}}>
            Não foram encontrados resultados
         </p>}
      </div>}
   </div>;
}

export default ProcurarProduto;