import React from 'react';
import {Line} from 'react-chartjs-2';

import './styles.scss';

const configGrafico = {
   responsive: true,
   maintainAspectRatio: false,
   scales: {
      yAxes: [{
         display: false,
         ticks: {
            beginAtZero: false
         }
      }],
      xAxes: [{
         display: false
      }]
   },
   legend: {
      display: false
   },
   elements: {
      point: {
         radius: 0
      },
      line: {
         tension: 0
      }
   },
   stepsize: 10
};

const Widget = ({estatisticas, cor, grafico}) => {
   let data = {
      labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"],
      datasets: [{
         label: 'Profit',
         data: grafico,
         backgroundColor: [
            cor + "20",
         ],
         borderColor: [
            cor,
         ],
         borderWidth: 2,
         fill: true
      }]
   };

   return <div className="col-md-4 stretch-card vendas-produto grid-margin">
      <div className="card card-statistics">
         <div className="card-body pb-0">
            {estatisticas.map((estatistica) => {
               return (
                  <div className={"widget-content"}>
                     <p className="text-dark widget-label">{estatistica.label}</p>
                     <div className="d-flex align-items-center">
                        <h4 className="text-dark widget-valor">{estatistica.valor}</h4>
                        {
                           !!estatistica.variacao && <h6 className={"widget-variacao ml-2 " + (estatistica.variacao < 0 ? "text-danger" : "text-success")}>{estatistica.variacao < 0 ? "" : "+"}{estatistica.variacao}</h6>
                        }
                     </div>
                  </div>
               )
            })}
         </div>
         <div className="graph-wrapper">
            <Line data={data} options={configGrafico}/>
         </div>
      </div>
   </div>

}

export default Widget;
