import React, {useEffect, useState} from 'react';
import {Button, Form, FormGroup} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {get, post, useVirbac} from "../../VirbacProvider";
import {useHistory, useLocation, useParams} from "react-router-dom";
import cogoToast from "cogo-toast";

import './styles.scss'

import Visualizar from "../../assets/images/visualizar_icon.svg";
import Esconder from "../../assets/images/esconder_icon.svg";

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const PrimeiroLogin = () => {
   const {register, handleSubmit, setError, errors, getValues} = useForm();
   const virbac = useVirbac();
   const {TokenPrimeiroAcesso} = useParams();
   const [utilizador, setUtilizador] = useState(null);
   const history = useHistory();
   const [mostrarPassword, setMostrarPassword] = useState(false);

   let hasReset = new URLSearchParams(useLocation().search).has("reset");

   useEffect(() => {
      (async () => {
         try {
            let utilizador = await get("utilizador/primeiro_acesso/detalhes", {TokenPrimeiroAcesso});
            setUtilizador(utilizador);
         } catch (e) {
            history.push("/");
            if (e.response.status === 404) {
               cogoToast.error('Este código de registo já não é válido. Entre em contacto connosco.', toastoptions);
            }
         }
      })()
   }, []);

   const onSubmit = async data => {
      await post("/utilizador/primeiro_acesso/definir_password", {
         TokenPrimeiroAcesso,
         Password: data.novaPassword
      }).then(async () => await virbac.login({
         utilizador: utilizador.Email || utilizador.Telefone,
         password: data.novaPassword
      }).then(() => cogoToast.success('Fez login com sucesso.', toastoptions)))
   };

   return <div className={"PrimeiroLogin"}>
      <div className={"card px-5 py-4"}>
         {utilizador &&
         <h1 className={"mb-3 font-weight-bold primary-color"}>Olá {utilizador.Nome}!</h1>
         }

         {!hasReset
            ? <p>Esta é a primeira vez que entra na nossa plataforma. Por favor crie uma password para continuar:</p>
            : <p>Por favor defina a sua nova password:</p>
         }

         <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className={"mb-3 password"} controlId={"novaPassword"}>
               <Form.Control name={"novaPassword"} type={mostrarPassword ? "text" : "password"} placeholder={"Nova Password"} ref={register({
                  required: true
               })}/>
               <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
               {errors.novaPassword?.type === "required" && <p className={"error-message"}>Este campo é obrigatório</p>}
            </FormGroup>

            <Form.Group className={"m-0 password"} controlId={"confirmacaoPassword"}>
               <Form.Control name={"confirmacaoPassword"} type={mostrarPassword ? "text" : "password"} placeholder={"Confirme a password"} ref={register({
                  required: true,
                  validate: value => value === getValues("novaPassword")
               })}/>
               <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
               {errors.confirmacaoPassword?.type === "required" && <p className={"error-message"}>Este campo é obrigatório</p>}
               {errors.confirmacaoPassword?.type === "validate" && <p className={"error-message"}>As passwords não coincidem!</p>}
            </Form.Group>

            <div className={"text-center mt-4"}>
               <Button name={"button"} className={"w-50 pt-2 pb-2"} variant="login" type={"submit"} ref={register}>
                  Submeter
               </Button>
               {errors.button?.type === "server" && <p className={"mb-0 error-message"}>{errors.button?.message}</p>}
            </div>
         </Form>
      </div>
   </div>
}

export default PrimeiroLogin;