import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
};

const submeter = async (valores, opcoes) => {
   if (opcoes.extra.acao === "editar") {
      valores.IdCampanha = opcoes.extra.IdCampanha;
      await post(`/marketing/sms/editar_campanha`, valores);
      cogoToast.success('Campanha SMS editada com sucesso!', toastoptions);
   } else if (opcoes.extra.acao === "adicionar") {
      await post("/marketing/sms/criar_campanha", valores);
      cogoToast.success('Campanha SMS criada com sucesso!', toastoptions);
   }
};

const NovaCampanhaSMS = {
   titulo: "Nova Campanha SMS",
   submeter,
   etapas
};

export default NovaCampanhaSMS;