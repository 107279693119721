import React, {useEffect, useState} from 'react';
import DataTable from "../../../components/DataTable";
import {get, post} from "../../../VirbacProvider";
import {useParams} from "react-router-dom";
import {useFormulario} from "../../../components/Formularios/Provider";
import {confirmAlert} from "react-confirm-alert";

import "./styles.scss";

import Editar from "../../../assets/images/editar_amarelo.svg";
import Eliminar from "../../../assets/images/icone_eliminar.svg";

const TabelaAnimais = () => {
   let {idCliente} = useParams();

   const [animais, setAnimais] = useState(null);

   const formulario = useFormulario();

   const obterAnimais = async () => {
      let animais = await get(`/cliente/${idCliente}/animal/listar`);
      setAnimais(animais);
   };

   useEffect(() => {
      obterAnimais();
   }, []);

   if (!animais)
      return null;

   let columns = [
      {
         dataField: 'Tipo',
         text: 'Tipo',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, animal) => {
            let tipo = "";
            if (animal.Tipo === "cao") {
               tipo = "Cão"
            } else if (animal.Tipo === "gato") {
               tipo = "Gato"
            }

            return (
               <p className={"m-0 primary-color"}>{tipo}</p>
            );
         }
      }, {
         dataField: 'Nome',
         text: 'Nome',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         dataField: 'Raca',
         text: 'Raça',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         dataField: 'Sexo',
         text: 'Sexo',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter(_, animal) {
            return (
               <p className={"m-0 primary-color"}>{animal.Sexo.toUpperCase()}</p>
            );
         }
      }, {
         dataField: 'DataNascimento',
         text: 'Data de Nascimento',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, animal) => {
            return (
               <p className={"m-0 primary-color"}>{animal.DataNascimento.split("-").reverse().join("-").replace(/^(0-)/, "")}</p>
            );
         }
      }, {
         dataField: 'Esterilizacao',
         text: 'Esterilização',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, animal) => {
            let esterilizacao;
            if (animal.Esterilizacao === 0) {
               esterilizacao = "Não"
            } else if (animal.Esterilizacao === 1) {
               esterilizacao = "Sim"
            }
            return (
               <p className={"m-0 primary-color"}>{esterilizacao}</p>
            );
         }
      },
      {
         key: "accoes",
         dataField: 'action',
         text: 'Ações',
         sort: false,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, animal) => {
            return (<>
               <img className={"cursor-pointer mr-2"} src={Editar} alt={"botao editar animal"} onClick={() => {
                  let [ano, mes, dia] = animal.DataNascimento.split("-");

                  formulario.abrirFormulario("cliente_adicionar_animal", {
                     titulo: `Editar ${animal.Nome}`,
                     valoresIniciais: {
                        ...animal,
                        DataNascimentoDia: dia,
                        DataNascimentoMes: mes,
                        DataNascimentoAno: ano
                     },
                     extra: {
                        acao: "editar",
                        idAnimal: animal.id,
                        idCliente: idCliente
                     },
                     formularioSubmetido: async () => {
                        await obterAnimais();
                     }
                  });
               }}/>
               <img className={"cursor-pointer"} src={Eliminar} alt={"botao eliminar animal"} onClick={() => {
                  confirmAlert({
                     title: `Apagar Animal?`,
                     message: `Confirma que pretende eliminar o animal '${animal.Nome}'? Esta ação é irreversível.`,
                     buttons: [
                        {
                           label: 'Sim',
                           onClick: async () => {
                              await post(`/cliente/${idCliente}/animal/apagar`, {
                                 id: animal.id
                              });
                              obterAnimais();
                           }
                        },
                        {label: 'Cancelar'}
                     ]
                  });
               }}
               />
            </>);
         }
      }
   ];

   return <div className={"TabelaAnimais"}>
      <DataTable
         data={animais}
         columns={columns}
         title={"Animais"}
         showNewButton={true}
         newButtonName={"Novo animal"}
         newOnClick={() => formulario.abrirFormulario("cliente_adicionar_animal", {
            extra: {
               idCliente: idCliente,
               acao: "adicionar"
            },
            formularioSubmetido: () => {
               obterAnimais();
            }
         })}
      />
   </div>
}

export default TabelaAnimais;