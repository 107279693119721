import React, {useEffect, useRef, useState} from 'react';
import DataTable from "../DataTable";
import {get} from "../../VirbacProvider";
import {Link, useHistory} from "react-router-dom";
import {useFormulario} from "../Formularios/Provider";

import './styles.scss'

let columns = (colunas_apresentar) => {
   let columns = [
      {
         key: "imagem",
         dataField: 'Imagem',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (imagem) => {
            return (
               <div style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/public/produtos/${imagem}_thumb.jpg)`}} className={"product-image"}/>
            )
         }
      }, {
         key: "codigo",
         dataField: 'Codigo',
         text: 'Referência',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (codigo, produto) => {
            return codigo + (produto.Ativo ? "" : " (inativo)");
         }
      }, {
         key: "nome",
         dataField: 'Nome',
         text: 'Nome',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         key: "pontos",
         dataField: 'Pontos',
         text: 'Pontos',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (pontos) => {
            return (
               <div className={"d-flex"}>
                  <div className={"badge badge-points primary-color mr-2 badge-inverse-dark"}>{`+${pontos}`}</div>
               </div>
            );
         }
      }, {
         key: "pontos_escalao",
         text: 'Pontos',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, produto) => {
            if (produto.PontosEscalao > 0)
               return <div className={"d-flex"}>
                  <div className={"badge badge-points primary-color mr-2 badge-inverse-dark"}>{`+${produto.Pontos}`}</div>
                  <div className="badge badge-points primary-color badge-inverse-dark">
                     <i className="badge-icon typcn typcn-gift primary-color mr-1"/>
                     {produto.PontosEscalao}
                  </div>
               </div>;
            return "-";
         }
      }, {
         key: "escalao",
         text: 'Escalão',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, produto) => {
            if (produto.PontosEscalao > 0)
               return <div className={"d-flex"}>
                  <div className="badge badge-points primary-color badge-inverse-dark">
                     <i className="badge-icon typcn typcn-gift primary-color mr-1"/>
                     {produto.PontosEscalao}
                  </div>
               </div>
            return "-";
         }
      }, {
         key: "accoes",
         dataField: 'action',
         text: 'Ações',
         sort: false,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, produto) => {
            return (
               <Link to={`/produtos/${produto.id}`} className={"primary-color underline m-0 text-nowrap"}>Ver Produto ></Link>
            );
         }
      }
   ];

   // Itera sobre as colunas desejadas e procura a configuração
   return colunas_apresentar.map(coluna_apresentar => {
      // Devolve coluna com o mesmo key
      return columns.find(c => {
         return c.key === coluna_apresentar;
      });
   });
}

const TabelaProdutos = (props) => {
   const numero_resultados = props.numero_resultados || 6;
   const [produtos, setProdutos] = useState(null);
   const [pagina, setPagina] = useState(0);
   const [pesquisa, setPesquisa] = useState("");
   const timeout = useRef(null);
   const formulario = useFormulario();
   const history = useHistory();

   let obterProdutos = async () => {
      let produtos = await get("/produto/listar", {
         pagina,
         numero_resultados,
         pesquisa
      });
      setProdutos(produtos);
   };

   useEffect(() => {
      clearTimeout(timeout.current);
      if (pesquisa !== null)
         timeout.current = setTimeout(() => {
            if (pagina === 0)
               obterProdutos();
            setPagina(0);
         }, 400)
   }, [pesquisa]);

   useEffect(() => {
      obterProdutos();
   }, [pagina]);

   if (!produtos)
      return null;

   return <div className={"TabelaProdutos"}>
      <DataTable
         data={produtos.resultados}
         columns={columns(props.colunas)}
         paginationOptions={{
            page: pagina + 1,
            sizePerPage: numero_resultados,
            totalSize: produtos.paginacao.total_resultados
         }}
         title={"Produtos"}
         showSearchBar={true}
         searchPlaceholder={"Pesquisar produto"}
         exportUrl={props.exportUrl}
         showNewButton={props.showNewButton}
         newButtonName={props.newButtonName}
         newOnClick={() => formulario.abrirFormulario("editar_produto", {
            titulo: "Novo produto",
            extra: {
               acao: "adicionar"
            },
            formularioSubmetido: async (resultado) => {
               history.push(`/produtos/${resultado.id}`);
            }
         })}
         onPageChange={page => setPagina(page - 1)}
         onSearchChange={setPesquisa}
      />
   </div>
};

export default TabelaProdutos;