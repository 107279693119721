import React from 'react';
import {Link} from "react-router-dom";

import './styles.scss';

const Footer = () => {
   return <div className={"Footer"}>
      <div className={"virbac-info-container"}>
         <div className={"row justify-content-between"}>
            <div className={"col-sm-3 virbac-info"}>
               <h4 className={"font-weight-bold mb-4"}>Virbac de Portugal <br/>Laboratórios, Lda.</h4>
               <p>Rua do Centro Empresarial,<br/>Edifício 13, piso 1, escritório 3,<br/>Quinta da Beloura, 2710-693 Sintra<br/><a href="https://pt.virbac.com" target="_blank" className="underline">https://pt.virbac.com</a></p>
            </div>

            <div className={"col-sm-3 virbac-info"}>
               <h4 className={"font-weight-bold mb-4"}>Apoio ao Cliente</h4>
               <p>Caso tenha alguma questão relacionada com o<br/>cartão cliente Virbac, pode enviar um email para<br/><a href="mailto:info@virbac.pt" target="_blank" className="underline">info@virbac.pt</a></p>
            </div>

            <div className={"col-sm-3 virbac-info"}>
               <h4 className={"font-weight-bold mb-4"}>Política de Privacidade</h4>
               <p>Conheça <Link to={"/politica-privacidade"} className={"underline"}>aqui</Link> a nossa política de privacidade</p>
            </div>
         </div>

         <div className={"row flex-column"}>
            <div className={"line"}></div>
            <p className={"mt-4"}>Copyright © 1999-{new Date().getFullYear()} Virbac. Todos os direitos reservados.</p>
         </div>
      </div>
   </div>
}

export default Footer;