import React, {useEffect, useRef, useState} from "react";
import CardProduto from "../CardProduto";
import {get} from "../../../VirbacProvider";
import {Element, scroller} from "react-scroll";

const ProdutosCliente = (props) => {
   const numero_resultados = 8;
   const [produtos, setProdutos] = useState(null);
   const [pagina, setPagina] = useState(null);
   const [pesquisa, setPesquisa] = useState(null);
   const timeout = useRef(null);
   let numero_paginas = produtos ? Math.ceil(produtos.paginacao.total_resultados / numero_resultados) : 0;

   let mudarPagina = (pagina) => {
      if (pagina < 0)
         pagina = 0;
      if (pagina >= numero_paginas)
         pagina = numero_paginas - 1;
      setPagina(pagina);
   };

   let obterProdutos = async () => {
      let produtos = await get("/produto/listar", {
         pagina,
         numero_resultados,
         pesquisa
      });
      setProdutos(produtos);
   };

   useEffect(() => {
      clearTimeout(timeout.current);
      if (pesquisa !== null)
         timeout.current = setTimeout(() => {
            if (pagina === 0)
               obterProdutos();
            setPagina(0);
         }, 400)
   }, [pesquisa]);

   useEffect(() => {
      obterProdutos();
   }, [pagina]);

   useEffect(() => {
      if (pagina !== null)
         scroller.scrollTo('produtos_cliente', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -20
         });
   }, [pagina]);

   useEffect(() => {
   }, [produtos]);

   return <Element name={'produtos_cliente'}>
      <div className="d-flex mt-2 justify-content-between buttons-table">
         <h2 className={"mb-4 font-weight-bold mr-3 flex-grow-1"}>Produtos</h2>
         <label className="search-label">
            <input type="text" className="form-control form-control-sm flex-grow-1" placeholder={"Pesquisar produto"} onChange={({target}) => setPesquisa(target.value)}/>
         </label>
      </div>

      <div className={"row mb-n4 produtos-cliente"}>
         {produtos && produtos.resultados.map((produto) => {
            return (
               <CardProduto
                  id={produto.id}
                  imagem={produto.Imagem}
                  referencia={produto.Codigo}
                  nome={produto.Nome}
                  pontos={produto.Pontos}
                  pontosOferta={produto.PontosEscalao}
                  pontosAtuais={props.cliente.Pontos}
               />
            )
         })}
         <div className="col-md-12 justify-content-center justify-content-md-end d-flex">
            {produtos && <ul className="pagination react-bootstrap-table-page-btns-ul">
               <li className="page-item" onClick={() => mudarPagina((pagina || 0) - 1)}>
                  <button type="button" className="btn outlined">Anterior</button>
               </li>
               {new Array(Math.min(numero_paginas, 5)).fill(0).map((_, idx) => {
                  let offset = (pagina || 0) + idx - 2;
                  if (offset < 0)
                     return null;
                  if (offset >= numero_paginas)
                     return null;
                  return <li className="page-item" onClick={() => mudarPagina(offset)}>
                     <button type="button" className={"btn outlined" + (offset === (pagina || 0) ? " active" : "")}>{offset + 1}</button>
                  </li>
               })}
               <li className="page-item" onClick={() => mudarPagina((pagina || 0) + 1)}>
                  <button type="button" className="btn outlined">Seguinte</button>
               </li>
            </ul>}
         </div>
      </div>
   </Element>;
};

export default ProdutosCliente;