import React, {useEffect, useState} from 'react';
import {get} from "../../VirbacProvider";

import Widget from "../../components/Widget";
import TabelaHistorico from "../../components/TabelaHistorico";
import TabelaNoticias from "./TabelaNoticias";

const Admin = () => {
   const [estatisticas, setEstatisticas] = useState(null);

   useEffect(() => {
      (async () => {
         setEstatisticas(await get("/admin/estatisticas"));
      })()
   }, []);

   return <div>
      {estatisticas &&
      <div className={"row"}>
         <Widget
            estatisticas={[{
               label: "Vendas este mês",
               valor: estatisticas.VendasMes,
               variacao: estatisticas.VendasMes - estatisticas.VendasMesAnterior
            }]}
            grafico={[25, 20, 40, 30, 15, 10, 50]}
            cor={"#b66dff"}/>
         <Widget
            estatisticas={[{
               label: "Mais vendido este mês",
               valor: estatisticas.ProdutoMaisVendido?.Nome || "-"
            }]}
            grafico={[25, 20, 40, 30, 15, 10, 50]}
            cor={"#e7bc4b"}/>
         <Widget
            estatisticas={[{
               label: "Novos clientes este mês",
               valor: estatisticas.ClientesMes,
            }]}
            grafico={[25, 20, 40, 30, 15, 10, 50]}
            cor={"#E37576"}
         />
      </div>
      }

      <TabelaHistorico
         titulo={"Histórico de Movimentos"}
         colunas={["clinica", "cliente", "data", "produto", "tipo_movimento", "comprovativo", "accoes"]}
      />

      <TabelaNoticias
         exportUrl={`${process.env.REACT_APP_API_URL}/noticia/listar?exportar_excel`}
      />
   </div>
};

export default Admin;