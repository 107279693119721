import React, {useEffect, useRef, useState} from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {get, post} from "../../../VirbacProvider";
import {useFormulario} from "../../../components/Formularios/Provider";

import DataTable from "../../../components/DataTable";
import EliminarEscalao from "../../../assets/images/icone_eliminar.svg";
import EditarEscalao from "../../../assets/images/editar_amarelo.svg";

import "./styles.scss";

const TabelaEscaloes = () => {
   const numero_resultados = 3;
   const [escaloes, setEscaloes] = useState(null);
   const [pagina, setPagina] = useState(0)
   const formulario = useFormulario();

   const obterEscaloes = async () => {
      let escaloes = await get("/produto/escalao/listar", {
         pagina,
         numero_resultados
      });
      setEscaloes(escaloes);
   }

   useEffect(() => {
      obterEscaloes();
   }, [pagina]);

   const callbackReloadTabelaEscaloes = useRef(null);
   const reloadTabelaEscaloes = () => {
      callbackReloadTabelaEscaloes.current && callbackReloadTabelaEscaloes.current();
   }

   useEffect(() => {
      // Registar callback para recarregar listagem
      if (callbackReloadTabelaEscaloes)
         callbackReloadTabelaEscaloes.current = obterEscaloes;
   }, [])

   if (!escaloes)
      return null;

   let columns = [
      {
         dataField: 'Designacao',
         text: 'Escalão',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         dataField: 'Pontos',
         text: 'Pontos',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (pontos) => {
            return (
               <div className={"d-flex"}>
                  <div className="badge badge-points primary-color badge-inverse-dark">
                     <i className="badge-icon typcn typcn-gift primary-color mr-1"/>
                     {pontos}
                  </div>
               </div>
            );
         }
      }, {
         dataField: 'action',
         text: 'Ações',
         sort: false,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, escalao) => {
            return (
               <div className={"d-flex"}>
                  <img className={"mr-3 cursor-pointer"} src={EditarEscalao} alt={"botao editar escalao"} onClick={() => formulario.abrirFormulario("novo_escalao", {
                     titulo: "Editar escalão",
                     valoresIniciais: {
                        Designacao: escalao.Designacao,
                        Pontos: escalao.Pontos
                     },
                     extra: {
                        acao: "editar",
                        IdEscalao: escalao.id
                     },
                     formularioSubmetido: () => {
                        reloadTabelaEscaloes();
                     }
                  })}/>
                  <img className={"cursor-pointer"} src={EliminarEscalao} alt={"botao eliminar escalao"} onClick={() => {
                     confirmAlert({
                        title: `Apagar escalão?`,
                        message: `Confirma que pretende eliminar o escalão '${escalao.Designacao}'? Esta ação é irreversível.`,
                        buttons: [
                           {
                              label: 'Sim',
                              onClick: async () => {
                                 await post("/produto/escalao/apagar", {
                                    id: escalao.id
                                 });
                                 reloadTabelaEscaloes();
                              }
                           },
                           {label: 'Cancelar'}
                        ]
                     });
                  }}
                  />
               </div>
            );
         }
      }
   ];

   return <div className={"TabelaEscaloes"}>
      <DataTable
         data={escaloes.resultados}
         columns={columns}
         paginationOptions={{
            page: pagina + 1,
            sizePerPage: numero_resultados,
            totalSize: escaloes.paginacao.total_resultados
         }}
         title={"Escalões"}
         showNewButton={true}
         newButtonName={"Novo escalão"}
         newOnClick={() => formulario.abrirFormulario("novo_escalao", {
            formularioSubmetido: () => {
               reloadTabelaEscaloes();
            },
            extra: {
               acao: "adicionar"
            }
         })}
         showExport={false}
         onPageChange={page => setPagina(page - 1)}
      />
   </div>
}

export default TabelaEscaloes;