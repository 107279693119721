import React from 'react';

import './styles.scss';

export default ({values, register, errors}) => {

   return <div className={"EliminarConta"}>
      <p>Quero ser esquecido: pretende eliminar a sua conta e todos os dados associados?</p>

      <p>Por favor note que esta acção é irreversível, todos os seus dados serão permanentemente eliminados.</p>

      <p>Se pretende continuar, confirme abaixo a sua intenção:</p>

      <div className="form-check mt-4">
         <label className="form-check-label label-checkbox" data-error={errors.EliminacaoAutorizada?.message}>
            <input name="EliminacaoAutorizada" type="checkbox" className="form-check-input" ref={register({
               required: "Por favor confirme o consentimento"
            })}/>
            <i className="input-helper"></i>
            Sim, pretendo remover permanentemente a minha conta e todos os dados associados, tendo consciencia que esta acção é irreversível.
         </label>
      </div>
   </div>
}