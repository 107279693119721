import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useVirbac} from "./VirbacProvider";

import Dashboard from './pages/DashboardClinica'
import Cliente from "./pages/Cliente";
import Clinica from "./pages/Clinica";
import Login from "./pages/Login";
import Produto from "./pages/Produto";
import Gerente from "./pages/DashboardGerente";
import Admin from "./pages/DashboardAdmin";
import Clinicas from "./pages/Clinicas";
import Delegados from "./pages/Delegados";
import Clientes from "./pages/Clientes";
import Produtos from "./pages/Produtos";
import DashboardCliente from "./pages/DashboardCliente";
import PrimeiroLogin from "./pages/PrimeiroLogin";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import Marketing from "./pages/Marketing";


const Routes = (props) => {
   const virbac = useVirbac();

   if (virbac.utilizador === null)
      return null;

   let routes = [];

   switch (virbac.utilizador.Tipo) {
      case "delegado":
         routes = [
            <Route exact path={"/dashboard"} component={Gerente}/>,
            <Route path={"/clinicas/:idClinica"} component={Clinica}/>
         ];
         break;
      case "clinica":
         routes = [
            <Route exact path={"/dashboard"} component={Dashboard}/>,
            <Route path={"/clientes/:idCliente"} component={Cliente}/>,
            <Route path={"/produtos/:idProduto"} component={Produto}/>
         ];
         break;
      case "admin":
         routes = [
            <Route exact path={"/dashboard"} component={Admin}/>,
            <Route exact path={"/clinicas"} component={Clinicas}/>,
            <Route path={"/clinicas/:idClinica"} component={Clinica}/>,
            <Route exact path={"/clientes"} component={Clientes}/>,
            <Route path={"/clientes/:idCliente"} component={Cliente}/>,
            <Route exact path={"/produtos"} component={Produtos}/>,
            <Route path={"/produtos/:idProduto"} component={Produto}/>,
            <Route exact path={"/delegados"} component={Delegados}/>,
            <Route exact path={"/marketing"} component={Marketing}/>
         ];
         break;
      case "cliente":
         routes = [
            <Route exact path={"/dashboard"} component={DashboardCliente}/>,
            <Route path={"/produtos/:idProduto"} component={Produto}/>
         ];
         break;
   }

   if (virbac.utilizador)
      return <Switch>
         {routes}
         <Route exact path={"/politica-privacidade"} component={PoliticaPrivacidade}/>
         <Redirect to={"/dashboard"}/>
      </Switch>;
   else
      return <Switch>
         <Route exact path={"/registo/:TokenPrimeiroAcesso"} component={PrimeiroLogin}/>
         <Route exact path={"/"} component={Login}/>
         <Route exact path={"/politica-privacidade"} component={PoliticaPrivacidade}/>
         <Redirect to={"/"}/>
      </Switch>
}

export default Routes;