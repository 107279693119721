import React, {useEffect, useRef, useState} from 'react';
import DataTable from "../DataTable";
import {get, post, useVirbac} from "../../VirbacProvider";
import {useFormulario} from "../Formularios/Provider";
import moment from "moment";
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import EditarMovimento from "../../assets/images/editar_amarelo.svg";
import AnularMovimento from "../../assets/images/icone_eliminar.svg";

import "./styles.scss";

const TabelaHistorico = (props) => {
   const numero_resultados = props.numeroResultados || 6;
   const [historico, setHistorico] = useState(null);
   const [pagina, setPagina] = useState(0);
   const [pesquisa, setPesquisa] = useState(null);
   const [filtroData, setFiltroData] = useState([null, null]);
   const virbac = useVirbac();
   const formulario = useFormulario();
   const timeout = useRef(null);

   let columns = (colunas_apresentar) => {
      let columns = [
         {
            key: "data",
            dataField: 'DataMovimento',
            text: 'Data',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <p className={"m-0"}>{moment(movimento.DataMovimento).format("DD/MM/YYYY")} {movimento.Anulado ? "(anulado)" : ""}</p>
               );
            }
         }, {
            key: "produto",
            text: 'Produto',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <p className={"m-0"}>{movimento.TipoMovimento === "anulacao" ? movimento.Quantidade : Math.abs(movimento.Quantidade)} x {movimento.CodigoProduto} - {movimento.NomeProduto}</p>
               );
            }
         }, {
            key: "cliente",
            text: 'Cliente',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <p className={"m-0"}>{movimento.CodigoCliente} - {movimento.NomeCliente}</p>
               );
            }
         }, {
            key: "pontos",
            text: 'Pontos',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <div className={"d-flex"}>
                     <div className={"badge badge-points primary-color mr-2 " + (movimento.Pontos > 0 ? "badge-inverse-success" : "badge-inverse-danger")}>{movimento.Pontos > 0 ? `+${movimento.Pontos}` : movimento.Pontos}</div>
                  </div>
               );
            }
         }, {
            key: "clinica",
            text: 'Clínica',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <p className={"m-0"}>{movimento.NomeClinica}</p>
               );
            }
         }, {
            key: "pontos_atuais",
            text: 'Pontos Totais',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <div className={"d-flex"}>
                     <div className={"badge badge-points primary-color mr-2 badge-inverse-dark"}>{movimento.PontosTotais}</div>
                  </div>
               );
            }
         }, {
            key: "tipo_movimento",
            text: 'Tipo',
            sort: true,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               return (
                  <div className={"d-flex"}>
                     {movimento.TipoMovimento === "venda" &&
                     <div className={"badge badge-points primary-color mr-2 badge-inverse-dark"}>Venda</div>
                     }
                     {movimento.TipoMovimento === "desconto" &&
                     <div className={"badge badge-points primary-color mr-2 badge-inverse-success"}>Desconto</div>
                     }
                     {movimento.TipoMovimento === "anulacao" &&
                     <div className={"badge badge-points primary-color mr-2 badge-inverse-danger"}>Anulação</div>
                     }
                  </div>
               );
            }
         }, {
            key: "comprovativo",
            dataField: 'Comprovativo',
            text: 'Comprovativo',
            sort: false,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (comprovativo) => {
               if (comprovativo) {
                  return <a href={`${process.env.REACT_APP_API_URL}/movimento/obter_comprovativo/${comprovativo}`} target="_blank" className={"primary-color underline m-0"}>Descarregar ></a>;
               } else
                  return <p className={"m-0 primary-color text-center"}>-</p>;
            }
         }, {
            key: "accoes",
            dataField: 'action',
            text: 'Ações',
            sort: false,
            headerStyle: {
               color: '#001737',
               fontWeight: '700',
               border: 'none'
            },
            formatter: (_, movimento) => {
               if (movimento.Anulado || movimento.TipoMovimento === "anulacao")
                  return null;
               return (
                  <div className={"d-flex justify-content-end"}>
                     {virbac.utilizador.Tipo === "admin" && movimento.TipoMovimento === "venda" &&
                     <img className={"cursor-pointer mr-3"} src={EditarMovimento} alt={"botao editar movimento"} onClick={() => formulario.abrirFormulario("editar_movimento", {
                        valoresIniciais: {
                           Cliente: JSON.stringify({
                              Nome: movimento.NomeCliente,
                              NumeroCartao: movimento.CodigoCliente
                           }),
                           produtos: [{
                              codigo: JSON.stringify({
                                 Codigo: movimento.CodigoProduto,
                                 Nome: movimento.NomeProduto,
                              }),
                              quantidade: movimento.Quantidade,
                           }],
                        },
                        extra: {
                           IdMovimento: movimento.IdMovimento,
                           IdProduto: movimento.IdProduto
                        },
                        formularioSubmetido: async () => {
                           await obterHistorico();
                           props.onReload();
                        }
                     })}/>
                     }
                     {virbac.utilizador.Tipo === "admin" &&
                     <img className={"cursor-pointer"} src={AnularMovimento} alt={"botao anular movimento"} onClick={() => {
                        confirmAlert({
                           title: `Confirmação`,
                           message: `Pretende anular o movimento do(a) cliente '${movimento.NomeCliente}'?`,
                           buttons: [
                              {
                                 label: 'Sim',
                                 onClick: async () => {
                                    await post("/movimento/anular", {
                                       id: movimento.IdMovimento
                                    });
                                    await obterHistorico();
                                    props.onReload && props.onReload();
                                 }
                              },
                              {label: 'Cancelar'}
                           ]
                        });
                     }}
                     />
                     }
                  </div>
               );
            }
         }
      ];

      // Itera sobre as colunas desejadas e procura a configuração
      return colunas_apresentar.map(coluna_apresentar => {
         // Devolve coluna com o mesmo key
         return columns.find(c => {
            return c.key === coluna_apresentar;
         });
      });
   }

   const obterHistorico = async () => {
      let historico = await get("/movimento/listar", {
         ...props.filtro,
         pagina,
         numero_resultados,
         pesquisa,
         data_inicio: filtroData[0],
         data_fim: filtroData[1]
      });
      setHistorico(historico);
   };

   useEffect(() => {
      clearTimeout(timeout.current);
      if (pesquisa !== null)
         timeout.current = setTimeout(() => {
            if (pagina === 0)
               obterHistorico();
            setPagina(0);
         }, 400);
   }, [pesquisa]);

   useEffect(() => {
      obterHistorico();
   }, [pagina, filtroData]);

   useEffect(() => {
      // Registar callback para recarregar listagem
      if (props.callbackReload)
         props.callbackReload.current = obterHistorico;
   }, [])

   if (!historico)
      return null;

   let params = new URLSearchParams({
      ...props.filtro,
      data_inicio: filtroData[0],
      data_fim: filtroData[1]
   });

   return <DataTable
      className={"TabelaHistorico"}
      data={historico.resultados}
      columns={columns(props.colunas)}
      paginationOptions={{
         page: pagina + 1,
         sizePerPage: numero_resultados,
         totalSize: historico.paginacao.total_resultados
      }}
      title={props.titulo}
      showSearchBar={true}
      searchPlaceholder={"Pesquisar histórico"}
      exportUrl={!props.simpleActions && `${process.env.REACT_APP_API_URL}/movimento/listar?exportar_excel&${params.toString()}`}
      onPageChange={page => setPagina(page - 1)}
      onSearchChange={setPesquisa}
      enableRange={!props.simpleActions}
      range={filtroData}
      onRangeChange={(from, until) => {
         setFiltroData([from, until]);
      }}
   />
}

export default TabelaHistorico;