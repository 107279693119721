import {post} from "../../../../VirbacProvider";
import {serialize} from 'object-to-formdata';
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.id = opcoes.extra.IdProduto;
   valores.Imagem = valores.Imagem[0];
   valores.Ativo ? valores.Ativo = 1 : valores.Ativo = 0;
   valores = serialize(valores);

   if (opcoes.extra.acao === "editar") {
      let produto = await post(`/produto/editar`, valores);
      cogoToast.success('Produto editado com sucesso!', toastoptions);

      return produto;
   } else if (opcoes.extra.acao === "adicionar") {
      let produto = await post("/produto/adicionar", valores);
      cogoToast.success('Produto criado com sucesso!', toastoptions);

      return produto;
   }
};

const EditarProduto = {
   titulo: "Editar produto",
   submeter,
   etapas
};

export default EditarProduto;