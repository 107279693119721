import React, {useState} from 'react';
import Slider from "react-slick";
import moment from "moment";
import "moment/locale/pt";
import {Modal} from 'react-bootstrap';
import {get} from "../../VirbacProvider";
import parse from "html-react-parser";

import "./styles.scss";

moment.locale("pt");

const Noticias = (props) => {
   const [detail, setDetail] = useState(null);

   if (!props.data)
      return null;

   return <div className={"Noticias"}>
      <div className={" card"}>
         <Modal
            className="NoticiasModal"
            show={!!detail}
            onHide={() => setDetail(null)}>
            <Modal.Header closeButton>
               <Modal.Title>{detail?.Titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/public/noticias/${detail?.Imagem}.jpg)`}} className={"imagem-detalhe"}/>
               {detail && parse(detail?.Corpo.replace(/\n/g, "<br>"))}
            </Modal.Body>
         </Modal>
         <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
         >
            {props.data.map((noticia) => {
               let dataNoticia = moment(noticia.DataCriacao);

               return <div className={"noticia"}>
                  <div className={"row m-0"}>
                     <div style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/public/noticias/${noticia.Imagem}_thumb.jpg)`}} className={"col-md-4 p-0 imagem-noticia"}/>
                     <div className={"col-md card-padding"}>
                        <p className={"primary-color carousel-news-title"}>{noticia.Titulo}</p>
                        <div className={"d-flex carousel-news-details"}>
                           <p className={"secondary-color carousel-news-date"}>
                              Publicado {dataNoticia.format("D [de] ")}
                              <span className={"text-capitalize"}>{dataNoticia.format("MMMM ")}</span>
                              <span>{dataNoticia.format("[de] YYYY")}</span>
                           </p>
                           <p className={"primary-color corpo"}>
                              {parse(noticia?.Corpo.replace(/\n/g, "<br>"))}
                           </p>
                           <a className={"primary-color carousel-news-all cursor-pointer"}
                              style={{textDecoration: "underline"}}
                              onClick={async () => {
                                 setDetail(await get("noticia/" + noticia.id))
                              }}>Continuar a ler >
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            })}
         </Slider>
      </div>
   </div>
}

export default Noticias;