import React, {useState} from "react";

import "./styles.scss";

import Visualizar from "../../../../assets/images/visualizar_icon.svg";
import Esconder from "../../../../assets/images/esconder_icon.svg";

export default ({values, register, errors}) => {
   const [mostrarPassword, setMostrarPassword] = useState(false);

   return <div className={"EditarClinica"}>
      <div className="form-group">
         <label data-error={errors.NomeComercial?.message}>Nome Comercial
            <input ref={register} name="NomeComercial" placeholder="Nome Comercial" type="text" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="form-group">
         <label data-error={errors.PasswordAtual?.message || errors.PasswordNova?.message} className={"w-100"}>
            Alterar password
            <div className="row">
               <div className="col-md-6">
                  <label className={"password"}>
                     <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                     <input ref={register({
                        validate: (valor) => valor && !values.PasswordNova ? "Para alterar a password indique a password nova" : true
                     })} name="PasswordAtual" placeholder="Password Atual" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                  </label>
               </div>
               <div className="col-md-6">
                  <label className={"password"}>
                     <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                     <input ref={register({
                        validate: (valor) => valor && !values.PasswordAtual ? "Para alterar a password indique a password atual" : true
                     })} name="PasswordNova" placeholder="Nova password" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                  </label>
               </div>
            </div>
         </label>
      </div>
   </div>;
}