import React, {useState} from "react";
import verificarNif from "../../../../functions/verificarNif";

import './styles.scss';

import Visualizar from "../../../../assets/images/visualizar_icon.svg";
import Esconder from "../../../../assets/images/esconder_icon.svg";

export default ({values, register, errors}) => {
   const [mostrarPassword, setMostrarPassword] = useState(false);

   return <div className={"EditarCliente"}>
      <div className="row">
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Nome?.message}>
                  Nome Completo*
                  <input ref={register({
                     required: "Por favor indique o nome completo do cliente"
                  })} name="Nome" placeholder="Nome Completo" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Contribuinte?.message}>
                  Contribuinte*
                  <input ref={register({
                     required: "Por favor indique o contribuinte",
                     validate: (valor) => verificarNif(valor) ? true : "O número de contribuinte não é válido"
                  })} name="Contribuinte" placeholder="Contribuinte" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className={"row"}>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Email?.message}>
                  Email
                  <input ref={register({
                     required: values.Telefone ? false : "Por favor indique o email",
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "O email não é válido"
                     }
                  })} name="Email" placeholder="Email" type="email" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Telefone?.message}>
                  Telefone
                  <input ref={register({
                     required: values.Email ? false : "Por favor indique o telemóvel",
                  })} name="Telefone" placeholder="Telemóvel" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="form-group">
         <label data-error={errors.Morada?.message}>
            Morada
            <input ref={register({
               required: "Por favor indique a morada"
            })} name="Morada" placeholder="Morada" type="text" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="row">
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.CodigoPostal?.message}>
                  Código Postal
                  <input ref={register({
                     required: "Por favor indique o código postal"
                  })} name="CodigoPostal" placeholder="Código Postal" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
               <label data-error={errors.Localidade?.message}>
                  Localidade
                  <input ref={register({
                     required: "Por favor indique a localidade"
                  })} name="Localidade" placeholder="Localidade" type="text" className="form-control form-control-sm"/>
               </label>
            </div>
         </div>
      </div>
      <div className="form-group">
         <label data-error={errors.PasswordAtual?.message || errors.PasswordNova?.message} className={"w-100"}>
            Alterar password
            <div className="row">
               <div className="col-md-6">
                  <label className={"password"}>
                     <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                     <input ref={register({
                        validate: (valor) => valor && !values.PasswordNova ? "Para alterar a password indique a password nova" : true
                     })} name="PasswordAtual" placeholder="Password Atual" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                  </label>
               </div>
               <div className="col-md-6">
                  <label className={"password"}>
                     <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                     <input ref={register({
                        validate: (valor) => valor && !values.PasswordAtual ? "Para alterar a password indique a password atual" : true
                     })} name="PasswordNova" placeholder="Nova password" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                  </label>
               </div>
            </div>
         </label>
      </div>
      <div className="form-group">
         <label data-error={errors.Observacoes?.message}>
            Observações
            <textarea ref={register} name="Observacoes" placeholder="Observações" className="form-control form-control-sm"/>
         </label>
      </div>
      <div className="form-check mt-4">
         <label className="form-check-label label-checkbox">
            <input name="MarketingAutorizado" type="checkbox" className="form-check-input" ref={register}/>
            <i className="input-helper"></i>
            Os meus dados pessoais podem ser utilizados para enviar informação sobre os produtos, campanhas e serviços Virbac.
         </label>
      </div>
   </div>;
}