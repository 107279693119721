import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "1. Dados de Cliente",
      formulario: require("./0").default
   },
   {
      titulo: "2. Animais",
      formulario: require("./1").default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores, opcoes) => {
   valores.Animais.forEach((animal) => {
      animal.DataNascimento = [animal.DataNascimentoAno, animal.DataNascimentoMes, animal.DataNascimentoDia].join('-');
   })

   let cliente = await post("/cliente/adicionar", valores);
   cogoToast.success('Cliente criado com sucesso!', toastoptions);

   return cliente;
};

const NovoCliente = {
   titulo: "Novo Cliente",
   submeter,
   etapas
};

export default NovoCliente;