import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {get} from "../../VirbacProvider";
import {useFormulario} from "../Formularios/Provider";

import DataTable from "../DataTable";

import "./styles.scss";

const TabelaClientes = (props) => {
   const numero_resultados = props.numero_resultados || 6;
   const formulario = useFormulario();
   const history = useHistory();

   const [clientes, setClientes] = useState(null);
   const [pagina, setPagina] = useState(0);
   const [pesquisa, setPesquisa] = useState("");
   const timeout = useRef(null);

   let columns = [
      {
         dataField: 'NumeroCartao',
         text: 'Nº Cartão',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, {
         dataField: 'Nome',
         text: 'Nome',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter(_, cliente) {
            return (
               <p className={"m-0 primary-color"}>{cliente.Nome} {cliente.Apelido}</p>
            );
         }
      }, {
         dataField: 'Email',
         text: 'Email',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      },/* {
         dataField: 'Localidade',
         text: 'Localidade',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         }
      }, */{
         dataField: 'Pontos',
         text: 'Pontos',
         sort: true,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, cliente) => {
            return (
               <div className={"d-flex"}>
                  <div className={"badge badge-points primary-color mr-2 badge-inverse-dark"}>{cliente.Pontos || 0}</div>
                  {cliente.PontosEscalao > 0 && <div className="badge badge-points primary-color badge-inverse-dark" title={"Escalão"}>
                     <i className="badge-icon typcn typcn-gift primary-color mr-1"/>
                     {cliente.PontosEscalao}
                  </div>}
               </div>
            );
         }
      }, {
         dataField: 'action',
         text: 'Ações',
         sort: false,
         headerStyle: {
            color: '#001737',
            fontWeight: '700',
            border: 'none'
         },
         formatter: (_, cliente) => {
            return (
               <Link to={`/clientes/${cliente.id}`} className={"primary-color underline m-0 text-nowrap"}>Ver Cliente ></Link>
            );
         }
      }
   ];

   const obterClientes = async () => {
      let clientes = await get("/cliente/listar", {
         ...props.filtro,
         pagina,
         numero_resultados,
         pesquisa
      });
      setClientes(clientes);
   };

   useEffect(() => {
      clearTimeout(timeout.current);
      if (pesquisa !== null)
         timeout.current = setTimeout(() => {
            if (pagina === 0)
               obterClientes();
            setPagina(0);
         }, 400)
   }, [pesquisa]);

   useEffect(() => {
      obterClientes();
   }, [pagina]);

   if (!clientes)
      return null;

   return <div className={"TabelaClientes"}>
      <DataTable
         data={clientes.resultados}
         columns={columns}
         paginationOptions={{
            page: pagina + 1,
            sizePerPage: numero_resultados,
            totalSize: clientes.paginacao.total_resultados
         }}
         title={"Clientes"}
         showSearchBar={true}
         searchPlaceholder={"Pesquisar cliente"}
         showNewButton={props.showNewButton}
         newOnClick={() => formulario.abrirFormulario("novo_cliente", {
            formularioSubmetido: async (resultado) => {
               history.push(`/clientes/${resultado.id}`);
            }
         })}
         newButtonName={"Novo cliente"}
         exportUrl={props.exportUrl}
         onPageChange={page => setPagina(page - 1)}
         onSearchChange={setPesquisa}
      />
   </div>
}

export default TabelaClientes;