import {post} from "../../../../VirbacProvider";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "",
      formulario: require('./0').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
};

const submeter = async (valores, opcoes) => {
   await post(`/utilizador/recuperar_password`, valores);
   cogoToast.success('Pedido de recuperação de password submetido com sucesso. Verifique o seu e-mail ou telemóvel.', toastoptions);
};

const RecuperarPassword = {
   titulo: "Recuperar Password",
   submeter,
   etapas
};

export default RecuperarPassword;