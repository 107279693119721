import React from 'react';

import {Link} from "react-router-dom";

import './styles.scss';

const CardProduto = (props) => {

   return <div className={"col-sm-6 col-md-4 col-lg-3 stretch-card CardProduto mb-4"}>
      <div className={"d-flex flex-column card card-padding"}>
         <div className={"mb-2 product-image"} style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/public/produtos/${props.imagem}.jpg)`}}/>
         <div className={"mb-3"}>
            <p className={"mb-1 secondary-color"}>{props.referencia}</p>
            <p className={"mb-1"}>{props.nome}</p>
            <Link to={`/produtos/${props.id}`} className={"primary-color underline ver-produto text-nowrap"}>Ver Produto ></Link>
         </div>

         <div className={"d-flex flex-column align-items-center mt-2"}>
            <div className={"badge badge-points primary-color badge-inverse-dark align-self-center"}>Acumule {props.pontos} pontos</div>
            <p className={"mb-1 mt-1 secondary-color"}>ou</p>
            {props.pontosAtuais >= props.pontosOferta
               ? <div className={"badge badge-points primary-color align-self-center badge-inverse-success"}>Desconto por {props.pontosOferta} pontos</div>
               : <div className={"badge badge-points primary-color align-self-center badge-inverse-dark"}>
                  <p className={"mb-1"}>Desconto por {props.pontosOferta} pontos</p>
                  <p className={"m-0 pontos-falta text-uppercase"}>Faltam {props.pontosOferta - (props.pontosAtuais || 0)} pontos</p>
               </div>
            }
         </div>
      </div>
   </div>
};

export default CardProduto;