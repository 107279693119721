import React, {useState} from "react";
import moment from "moment";
import "moment/locale/pt";

import "./styles.scss";

moment.locale("pt");

export default ({values, register, errors}) => {
   const [numeroAnimais, setNumeroAnimais] = useState(values.Animais ? values.Animais.length : 1);

   return <div className="NovoCliente">
      {[...Array(numeroAnimais).keys()].map(idxAnimal => {
         return <div className="Animal">
            <h3>Animal #{idxAnimal + 1}
               {idxAnimal > 0 && idxAnimal + 1 === numeroAnimais && <a className={"acao_animal remover"} onClick={() => setNumeroAnimais(numeroAnimais - 1)}>Remover</a>}
            </h3>
            <div className="row">
               <div className="col-md-4">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.Tipo?.message}>
                        Espécie de Animal
                        <select
                           name={"Animais[" + idxAnimal + "].Tipo"}
                           className={"form-control" + (values.Animais && values.Animais[idxAnimal]?.Tipo ? " ok" : "")}
                           ref={register({
                              required: "Por favor indique o tipo de animal"
                           })}>
                           <option value={""} disabled selected>Espécie</option>
                           <option value={"cao"}>Cão</option>
                           <option value={"gato"}>Gato</option>
                        </select>
                     </label>
                  </div>
               </div>
               <div className="col-md-8">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.Nome?.message}>
                        Nome
                        <input
                           ref={register({
                              required: "Por favor indique o nome do animal"
                           })}
                           name={"Animais[" + idxAnimal + "].Nome"}
                           placeholder="Nome"
                           type="text"
                           className="form-control form-control-sm"/>
                     </label>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-5">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.Raca?.message}>Raça*
                        <input ref={register({
                           required: "Por favor indique a raça do animal"
                        })} name={"Animais[" + idxAnimal + "].Raca"} placeholder="Raça" type="text" className="form-control form-control-sm"/>
                     </label>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.Sexo?.message}>
                        Sexo*
                        <select
                           name={"Animais[" + idxAnimal + "].Sexo"}
                           className={"form-control" + (values.Animais && values.Animais[idxAnimal]?.Sexo ? " ok" : "")}
                           ref={register({
                              required: "Por favor indique o sexo do animal"
                           })}>
                           <option value={""} disabled selected>Sexo</option>
                           <option value={"m"}>Masculino</option>
                           <option value={"f"}>Feminino</option>
                        </select>
                     </label>
                  </div>
               </div>
               <div className="col-md-3">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.Esterilizacao?.message}>
                        Esterilização*
                        <select
                           name={"Animais[" + idxAnimal + "].Esterilizacao"}
                           className={"form-control" + (values.Animais && typeof values.Animais[idxAnimal]?.Esterilizacao !== "undefined" && values.Animais[idxAnimal]?.Esterilizacao !== "" ? " ok" : "")}
                           ref={register({
                              required: "Por favor indique se o animal é esterilizado ou não"
                           })}>
                           <option value={""} disabled selected>Selecione</option>
                           <option value={0}>Não</option>
                           <option value={1}>Sim</option>
                        </select>
                     </label>
                  </div>
               </div>
            </div>

            <div className="form-group" style={{margin: 0}}>
               <label>Data de nascimento*</label>
            </div>

            <div className="row data-nascimento">
               <div className="col-4 col-md-3">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.DataNascimentoDia?.message}>
                        <select
                           name={"Animais[" + idxAnimal + "].DataNascimentoDia"}
                           className={"form-control" + (values.Animais && values.Animais[idxAnimal]?.DataNascimentoDia ? " ok" : "")}
                           ref={register({
                              required: "Por favor indique o dia de nascimento"
                           })}>
                           <option value={""} disabled selected>Dia</option>
                           <option value={0}>Não sei</option>
                           {[...Array(31).keys()].map(i => <option value={i + 1}>{i + 1}</option>)}
                        </select>
                     </label>
                  </div>
               </div>
               <div className="col-5 col-md-3">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.DataNascimentoMes?.message}>
                        <select
                           name={"Animais[" + idxAnimal + "].DataNascimentoMes"}
                           className={"form-control" + (values.Animais && values.Animais[idxAnimal]?.DataNascimentoMes ? " ok" : "")}
                           ref={register({
                              required: "Por favor indique o mês de nascimento"
                           })}>
                           <option value={""} disabled selected>Mês</option>
                           {[...Array(12).keys()].map(i => {
                              let mes = moment().set('month', i).format("MMMM");
                              return <option value={i + 1}>{mes.charAt(0).toUpperCase() + mes.slice(1)}</option>
                           })}}
                        </select>
                     </label>
                  </div>
               </div>
               <div className="col-3 col-md-3">
                  <div className="form-group">
                     <label data-error={errors.Animais && errors.Animais[idxAnimal]?.DataNascimentoAno?.message}>
                        <select
                           name={"Animais[" + idxAnimal + "].DataNascimentoAno"}
                           className={"form-control" + (values.Animais && values.Animais[idxAnimal]?.DataNascimentoAno ? " ok" : "")}
                           ref={register({
                              required: "Por favor indique o ano de nascimento"
                           })}>
                           <option value={""} disabled selected>Ano</option>
                           {[...Array(100).keys()].map(i => {
                              let ano = new Date().getFullYear() - i;
                              return <option value={ano}>{ano}</option>
                           })}
                        </select>
                     </label>
                  </div>
               </div>
            </div>
         </div>;
      })}
      <div className="Animal">
         <h3>
            Animal #{numeroAnimais + 1}
            <a className={"acao_animal"} onClick={() => setNumeroAnimais(numeroAnimais + 1)}>Adicionar</a>
         </h3>
      </div>
   </div>;
}