import {post} from "../../../../VirbacProvider";
import {serialize} from "object-to-formdata";
import cogoToast from "cogo-toast";

const etapas = [
   {
      titulo: "1. Dados de Venda",
      formulario: require('./0').default
   },
   {
      titulo: "2. Resumo",
      formulario: require('./1').default
   }
];

const toastoptions = {
   hideAfter: 5,
   position: 'top-right'
}

const submeter = async (valores) => {
   let cliente = JSON.parse(valores.Cliente);
   let comprovativo = valores.Comprovativo[0];
   let produtos = valores.produtos.map((produto) => {
      if (!produto.quantidade || parseInt(produto.quantidade) === 0 || !produto.codigo)
         return;
      produto.codigo = JSON.parse(produto.codigo)
      return {
         id: produto.codigo.id,
         quantidade: produto.quantidade
      }
   }).filter(p => p);

   let formData = serialize({Cliente: cliente.id, Produtos: produtos, Comprovativo: comprovativo}, {indices: true});

   let movimento = await post("/movimento/adicionar", formData);
   cogoToast.success('Movimento criado com sucesso!', toastoptions);

   return movimento;
};

const NovaVenda = {
   titulo: "Nova Venda",
   submeter,
   etapas
};

export default NovaVenda;