import React from 'react';
import TabelaClientes from "../../components/TabelaClientes";

const Clientes = () => {

   return <div className={"Clientes"}>
      <TabelaClientes
         numero_resultados={15}
         exportUrl={`${process.env.REACT_APP_API_URL}/cliente/listar?exportar_excel`}
      />
   </div>
};

export default Clientes;