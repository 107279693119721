import React, {useMemo, useRef, useState} from "react";
import obterConfiguracao from "./configuracoes/obterConfiguracao";
import WrapperFormulario from "./Wrapper";
import {Modal} from 'react-bootstrap';

import "./styles.scss";

const FormularioContext = React.createContext({});

const FormularioProvider = (props) => {
   const formulariosRef = useRef({});
   const [formularios, setFormularios] = useState(formulariosRef.current);

   const fecharFormulario = (key) => {
      if (!formulariosRef.current[key])
         return;

      formulariosRef.current[key].visivel = false;
      setFormularios({...formulariosRef.current});

      setTimeout(() => {
         delete formulariosRef.current[key];
         setFormularios({...formulariosRef.current});
      }, 1000);
   };

   const abrirFormulario = useMemo(() => {
      return (nome_formulario, opcoes = {}) => {
         let key = nome_formulario + ":" + Date.now();
         let configuracao = obterConfiguracao(nome_formulario);
         formulariosRef.current[key] = {
            key,
            visivel: true,
            configuracao: configuracao,
            opcoes,
            formulario: <WrapperFormulario
               key={key}
               {...opcoes}
               nome_formulario={nome_formulario}
               fecharFormulario={() => fecharFormulario(key)}
               configuracao={configuracao}/>
         };
         setFormularios({...formulariosRef.current});

         return () => fecharFormulario(key);
      }
   }, []);

   return <FormularioContext.Provider value={{abrirFormulario}}>
      {props.children}
      <div className="Formularios">
         {Object.values(formularios).map(({formulario, configuracao, opcoes, key, visivel}) => {
            return <Modal
               backdrop={'static'}
               className="FormularioModal"
               show={visivel}
               onHide={() => fecharFormulario(key)}>
               <Modal.Header closeButton>
                  <Modal.Title>{opcoes.titulo || configuracao.titulo}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {formulario}
               </Modal.Body>
            </Modal>
         })}
      </div>
   </FormularioContext.Provider>
};

const useFormulario = () => React.useContext(FormularioContext);

export default FormularioProvider;

export {useFormulario};